.wired.dark-theme,
.wireless.dark-theme {
  .focusable {
    background: transparent;
  }

  .right_column .explore__suggested-header,
  .right_column .explore__tags-header,
  .right_column .list-panel {
    background: transparent;
  }

  .account__fields {
    background: var(--ui-page-background);
  }

  .status.collapsed .status__content::after {
    background: linear-gradient(rgba(40, 44, 55, 0%), var(--ui-page-color));
  }
}

.wired.light-theme,
.wireless.light-theme {
  background: var(--ui-body-color) !important;

  .ui__header__wrapper {
    background-color: var(--ui-page-background);
  }

  .ui__header__wrapper::before {
    background: transparent;
  }

  &::before {
    background: transparent;
  }

  .ui__header__wrapper {
    background: var(--ui-body-color);
  }

  .focusable {
    background: var(--ui-body-color);
  }

  .right_column .explore__suggested-header,
  .right_column .explore__tags-header,
  .right_column .list-panel {
    background: var(--ui-body-color);
  }
}

.wired.dark-theme {
  .right_column .explore__suggested-header,
  .right_column .explore__tags-header,
  .right_column .list-panel {
    border: 2px solid var(--ui-body-color);
  }

  .account__fields {
    border: 1px solid var(--ui-body-color);

    dl {
      border-bottom: 1px solid var(--ui-body-color);
    }
  }
}

.wired.light-theme {
  .compose-form {
    .compose-form__autosuggest-wrapper textarea {
      border: 2px solid var(--ui-page-background);
      border-bottom: 0;
    }

    .compose-form__autosuggest-wrapper input {
      border: 2px solid var(--ui-page-background);
      border-top: 0;
      border-radius: 0 0 var(--ui-border-radius) var(--ui-border-radius);
    }
  }

  .focusable {
    border-color: var(--ui-page-background);

    &:focus {
      border-color: var(--ui-highlight-color);
    }
  }

  .right_column .explore__suggested-header,
  .right_column .explore__tags-header,
  .right_column .list-panel {
    border: 2px solid var(--ui-page-background);
  }

  .account__fields {
    border: 1px solid var(--ui-page-background);
  }
}

.smooshed {
  .item-list {
    margin-top: 8px;
  }

  .focusable,
  .notification,
  .status__wrapper--filtered {
    border-radius: 0;
    margin-top: 2px;
  }

  article:first-child .focusable {
    border-radius: var(--ui-border-radius) var(--ui-border-radius) 0 0;
  }

  article:last-child .focusable {
    border-radius: 0 0 var(--ui-border-radius) var(--ui-border-radius);
  }
}

.wired.smooshed {
  .focusable {
    border-bottom: 2px solid transparent;
    margin-top: -2px;

    &:focus {
      border-bottom: 2px solid var(--ui-highlight-color);
      z-index: 1;
    }
  }

  article:last-child .focusable {
    border-bottom: 2px solid var(--ui-body-color);
  }
}

.wireless.smooshed {
  .focusable {
    border-bottom: 2px solid transparent;
    margin-top: -2px;

    &:focus {
      border-bottom: 2px solid var(--ui-highlight-color);
      z-index: 1;
    }
  }

  article:last-child .focusable {
    border-bottom: 2px solid transparent;
  }
}

.wireless {
  .focusable {
    border-color: transparent;
  }

  .right_column .list-panel {
    background: transparent;
    border-color: transparent;
  }

  .account__fields {
    border-color: transparent;

    dl {
      border-bottom-color: transparent;
    }
  }

  .notification,
  .status__wrapper--filtered {
    border-color: transparent;
  }
}

.light-theme .ui__header__wrapper {
  background-color: var(--ui-body-color);
}
