/* More Visible CWs */

.spoiler_content {
  position: relative;
  margin-top: 15px;

  > span {
    max-width: calc(100% - 78px);
    font-size: 1rem;
    width: 100%;
    display: inline-block;
    font-weight: bold;
    padding: 8px;
    border: var(--ui-body-secondary-color) 2px solid;
    border-right: 0;
    box-sizing: border-box;
    margin-left: -22px;
    padding-left: 20px;
    background: var(--ui-body-secondary-color);
  }
}

.status__content__spoiler-link {
  display: inline-block;
  text-transform: uppercase;
  cursor: pointer;
  background: var(--ui-body-secondary-color);
  transition:
    background 0.2s,
    border 0.2s;
  color: var(--ui-font-color);
  padding: 8px;
  border: var(--ui-body-secondary-color) 2px solid;
  width: 120px;
  box-sizing: border-box;
  margin-left: -3px;
  position: absolute;
  height: 100%;
  font-size: 0.8rem;
  font-weight: 700;

  .status__content__spoiler-icon {
    display: inline-block;
    margin-inline-start: 5px;
    border-inline-start: 1px solid currentColor;
    padding: 0;
    padding-inline-start: 4px;
    font-size: 1rem;
    vertical-align: -2px;
  }

  &:hover {
    background: var(--ui-highlight-color);
    border: var(--ui-highlight-color) 2px solid;
    text-decoration: none;
    color: var(--ui-highlight-button-text-color);
  }
}

.muted {
  .spoiler_content > span {
    border: var(--ui-body-color) 2px solid;
    background: var(--ui-body-color);
    color: var(--ui-font-secondary-color);
  }

  .status__content__spoiler-link {
    background: var(--ui-body-color);
    border: var(--ui-body-color) 2px solid;

    &:hover {
      background: var(--ui-highlight-color);
      border: var(--ui-highlight-color) 2px solid;
    }
  }
}

.status__content__spoiler__link--visible {
  color: transparent;
  pointer-events: none;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

.hashtag_force_hidden {
  &.hashtag-hidden,
  &.hashtag-lockdown {
    display: none;
  }

  &.hashtag-obscured {
    cursor: pointer;
    font-family: flow-block, sans-serif;
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    pointer-events: none;
  }
}

.status__content__spoiler--obscured {
  cursor: pointer;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  display: block;
  font-family: flow-block, sans-serif;
  line-height: 1.1;

  .emojione {
    display: none;
  }

  a {
    pointer-events: none;
  }

  code {
    font-family: flow-block, sans-serif;
  }
}

.status__content__spoiler--hidden,
.status__content__spoiler--lockdown {
  display: none;
}
