.app-body {
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}

.animated-number {
  display: inline-flex;
  flex-direction: column;
  align-items: stretch;
  overflow: hidden;
  position: relative;
  -webkit-text-stroke: 0;
  color: var(--ui-highlight-color);
}

.reactions-bar__item__count .animated-number {
  color: var(--ui-font-color);
}

.link-button {
  display: block;
  font-size: 0.95rem;
  line-height: 1.25em;
  color: var(--ui-highlight-color);
  border: 0;
  background: transparent;
  padding: 0;
  cursor: pointer;
  text-decoration: none;

  // &--destructive {
  //  color: $error-value-color;
  // }

  &:hover,
  &:active {
    text-decoration: underline;
  }

  &:disabled {
    color: var(--ui-primary-color);
    cursor: default;
  }
}

.primary {
  min-width: 88px;
}

.button {
  background-color: var(--ui-highlight-color);
  border: 10px none;
  border-radius: 100px;
  box-sizing: border-box;
  color: var(--ui-highlight-button-text-color);
  cursor: pointer;
  display: inline-block;
  font-family: var(--ui-interface-font), sans-serif;
  font-size: 0.95rem;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 1.2em;
  overflow: hidden;
  padding: 7px 18px;
  position: relative;
  text-align: center;
  text-decoration: none;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: auto;
  @media screen and (prefers-reduced-motion: no-preference) {
    & {
      transition:
        background 0.2s,
        color 0.2s,
        box-shadow 0.2s;
    }
  }

  &:active,
  &:focus,
  &:hover {
    box-shadow: 0 0 8px var(--ui-highlight-color);
  }

  // &--destructive {
  //  &:active,
  //  &:focus,
  //  &:hover {
  //    background-color: $error-red;
  //    transition: none;
  //  }
  // }

  &:disabled {
    background-color: var(--ui-primary-color);
    cursor: default;

    &:active,
    &:focus,
    &:hover {
      box-shadow: 0 0 8px transparent;
    }
  }

  &.button-alternative {
    color: var(--ui-inverted-font-color);
    background: var(--ui-primary-color);

    &:active,
    &:focus,
    &:hover {
      background-color: var(--ui-base-lighter-color);
    }
  }

  &.button-alternative-2 {
    background: var(--ui-base-lighter-color);

    &:active,
    &:focus,
    &:hover {
      background-color: var(--ui-base-lighter-color);
    }
  }

  &.button-secondary {
    color: var(--ui-highlight-color);
    background: transparent;
    padding: 6px 17px;
    border: 2px solid var(--ui-highlight-color);

    &:not(:disabled):active,
    &:not(:disabled):focus,
    &:not(:disabled):hover {
      background: var(--ui-highlight-color);
      border-color: var(--ui-highlight-color);
      color: var(--ui-highlight-button-text-color);
      text-decoration: none;
    }

    &:disabled {
      opacity: 0.5;
      color: var(--ui-font-dimmer-color);
      border-color: var(--ui-font-dimmer-color);
      border-width: 1px;
    }
  }

  &.button-tertiary {
    background: transparent;
    padding: 6px 17px;
    color: var(--ui-highlight-color);
    border: 3px solid var(--ui-highlight-color);

    &:active,
    &:focus,
    &:hover {
      background: var(--ui-highlight-color);
      color: var(--ui-highlight-button-text-color);
    }

    &:disabled {
      opacity: 0.5;
    }

    &.button--confirmation {
      color: $valid-value-color;
      border-color: $valid-value-color;

      &:active,
      &:focus,
      &:hover {
        background: $valid-value-color;
        color: var(--ui-font-color);
      }
    }

    //    &.button--destructive {
    //      color: $error-value-color;
    //      border-color: $error-value-color;

    //      &:active,
    //      &:focus,
    //      &:hover {
    //        background: $error-value-color;
    //        color: var(--ui-font-color);
    //      }
    //    }
  }

  &.button-expand {
    background: transparent;
    padding: 4px 17px;
    color: var(--ui-highlight-color);
    border: 1px solid var(--ui-highlight-color);
    display: block;
    margin: 14px auto 0;
    width: max-content;
    font-size: 1rem;
    font-weight: bold;

    &:active,
    &:focus,
    &:hover {
      background: var(--ui-highlight-color);
      color: var(--ui-highlight-button-text-color);
    }
  }

  &.button--block {
    display: block;
    width: 100%;
  }
}

.icon-button {
  display: inline-block;
  padding: 0;
  color: var(--ui-highlight-color);
  border: 0;
  background: transparent;
  cursor: pointer;
  transition: all 100ms ease-in;
  transition-property: background-color, color;
  text-decoration: none;
  border-radius: 100%;

  a {
    color: inherit;
    text-decoration: none;
  }

  &:hover,
  &:active,
  &:focus {
    color: var(--ui-font-color);
    transition: all 200ms ease-out;
    transition-property: background-color, color;
  }

  &.disabled {
    color: darken($action-button-color, 13%);
    background-color: transparent;
    cursor: default;
  }

  &.active {
    color: var(--ui-font-color);
  }

  &.copyable {
    transition: background 300ms linear;
  }

  &.copied {
    transition: none;
  }

  &::-moz-focus-inner {
    border: 0;
  }

  &::-moz-focus-inner,
  &:focus,
  &:active {
    outline: 0 !important;
  }

  &.inverted {
    color: var(--ui-highlight-color);

    &:hover {
      color: var(--ui-highlight-button-text-color);
    }

    &.disabled {
      color: var(--ui-font-brighter-color);
      background-color: transparent;
    }

    &.active {
      color: var(--ui-font-color);

      &.disabled {
        color: var(--ui-highlight-color);
      }
    }
  }

  &.overlayed {
    box-sizing: content-box;
    color: var(--ui-highlight-color);
    padding: 2px;
  }

  &--with-counter {
    display: inline-flex;
    align-items: center;
    width: auto !important;
    padding: 0;
    padding-inline-end: 4px;
    padding-inline-start: 2px;
  }

  &__counter {
    display: inline-block;
    width: auto;
    margin-inline-start: 4px;
    font-size: 0.75rem;
    font-weight: 500;
  }
}

.text-icon,
.text-icon-button {
  font-weight: 600;
  font-size: 0.75rem;
  line-height: 2;
  cursor: default;
}

.text-icon-button {
  color: var(--ui-highlight-color);
  border: 0;
  border-radius: var(--ui-border-radius);
  background: transparent;
  cursor: pointer;
  padding: 0 3px;
  outline: 0;
  transition: all 100ms ease-in;
  transition-property: background-color, color;

  &:hover,
  &:active,
  &:focus {
    color: var(--ui-font-color);
    transition: all 200ms ease-out;
    transition-property: background-color, color;
  }

  &.disabled {
    /* TODO: Color used to be 20% brighter */
    color: var(--ui-font-brighter-color);
    background-color: transparent;
    cursor: default;
  }

  &.active {
    color: var(--ui-highlight-color);
  }

  &::-moz-focus-inner {
    border: 0;
  }

  &::-moz-focus-inner,
  &:focus,
  &:active {
    outline: 0 !important;
  }
}

body > [data-popper-placement] {
  z-index: 10000;
}

.invisible {
  font-size: 0;
  line-height: 0;
  display: inline-block;
  width: 0;
  height: 0;
  position: absolute;

  img,
  svg {
    margin: 0 !important;
    border: 0 !important;
    padding: 0 !important;
    width: 0 !important;
    height: 0 !important;
  }
}

.ellipsis {
  &::after {
    content: '…';
  }
}

.notification__favourite-icon-wrapper {
  inset-inline-start: 0;
  position: absolute;

  .fa.star-icon {
    color: var(--ui-highlight-color);
  }
}

.icon-button.star-icon.active {
  color: var(--ui-highlight-color);
}

.icon-button.bookmark-icon.active {
  color: var(--ui-highlight-color);
}

@media screen and (prefers-reduced-motion: no-preference) {
  .icon-button.star-icon {
    &.activate {
      & > .fa-star {
        animation: spring-rotate-in 1s linear;
      }
    }

    &.deactivate {
      & > .fa-star {
        animation: spring-rotate-out 1s linear;
      }
    }
  }
}

.notification__display-name {
  color: inherit;
  font-weight: 500;
  text-decoration: none;

  &:hover {
    color: var(--ui-font-color);
    text-decoration: underline;
  }
}

.display-name {
  display: block;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  a {
    color: inherit;
    text-decoration: inherit;
  }

  strong {
    display: block;
  }

  > a:hover {
    strong {
      text-decoration: underline;
    }
  }

  &.inline {
    padding: 0;
    height: 18px;
    font-size: 0.95rem;
    line-height: 1.1;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;

    strong {
      display: inline;
      height: auto;
      font-size: inherit;
      line-height: inherit;
    }

    span {
      display: inline;
      height: auto;
      font-size: inherit;
      line-height: inherit;
    }
  }
}

.display-name__html {
  font-weight: 500;
}

.display-name__account {
  font-size: 0.9rem;
}

.image-loader {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE 10+ */

  * {
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* IE 10+ */
  }

  &::-webkit-scrollbar,
  *::-webkit-scrollbar {
    width: 0;
    height: 0;
    background: transparent; /* Chrome/Safari/Webkit */
  }

  .image-loader__preview-canvas {
    max-width: $media-modal-media-max-width;
    max-height: $media-modal-media-max-height;
    background: url('~images/void.png') repeat;
    object-fit: contain;
  }

  .loading-bar__container {
    position: relative;
  }

  .loading-bar {
    position: absolute;
  }

  &.image-loader--amorphous .image-loader__preview-canvas {
    display: none;
  }
}

.zoomable-image {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    max-width: $media-modal-media-max-width;
    max-height: $media-modal-media-max-height;
    width: auto;
    height: auto;
    object-fit: contain;
    backface-visibility: hidden;
  }
}

.dropdown-animation {
  animation: dropdown 150ms cubic-bezier(0.1, 0.7, 0.1, 1);

  @keyframes dropdown {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }

  @media screen and (prefers-reduced-motion) {
    & {
      animation: none;
    }
  }
}

.dropdown {
  display: inline-block;
}

.dropdown__content {
  display: none;
  position: absolute;
}

.dropdown-menu__separator {
  border-bottom: 2px solid var(--ui-highlight-button-text-color);
  margin: 5px 7px 6px;
  height: 0;
}

.dropdown-menu {
  background: var(--ui-highlight-color);
  border-radius: 8px;
  box-shadow: 2px 4px 15px var(--ui-dropdown-shadow);
  z-index: 9999;
  overflow: hidden;
  color: var(--ui-highlight-button-text-color);

  &__text-button {
    display: inline;
    color: inherit;
    background: transparent;
    border: 0;
    margin: 0;
    padding: 0;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;

    &:focus {
      outline: 1px dotted;
    }
  }

  &__container {
    &__header {
      border-bottom: 1px solid var(--ui-body-secondary-color);
      padding: 4px 14px;
      padding-bottom: 8px;
      font-size: 0.8rem;
      line-height: 1.1em;
      color: var(--ui-highlight-button-text-color);
    }

    &__list {
      list-style: none;

      &--scrollable {
        max-height: 300px;
        overflow-y: scroll;
      }
    }

    &--loading {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 30px 45px;
    }
  }
}

.dropdown-menu__arrow {
  position: absolute;

  &::before {
    content: '';
    display: block;
    width: 14px;
    height: 5px;
    background-color: var(--ui-highlight-color);
    mask-image: url("data:image/svg+xml;utf8,<svg width='14' height='5' xmlns='http://www.w3.org/2000/svg'><path d='M7 0L0 5h14L7 0z' fill='white'/></svg>");
  }

  &.top {
    bottom: -5px;

    &::before {
      transform: rotate(180deg);
    }
  }

  &.right {
    inset-inline-start: -9px;

    &::before {
      transform: rotate(-90deg);
    }
  }

  &.bottom {
    top: -5px;
  }

  &.left {
    inset-inline-end: -9px;

    &::before {
      transform: rotate(90deg);
    }
  }
}

.dropdown-menu__item {
  font-size: 1.1rem;
  font-weight: 500;
  display: block;
  color: var(--ui-highlight-button-text-color);

  &--dangerous {
    color: var(--ui-highlight-button-text-color);
  }

  a,
  button {
    display: block;
    width: 100%;
    padding: 0.5em 1em;
    border: 0;
    margin: 0;
    background: transparent;
    box-sizing: border-box;
    text-decoration: none;
    color: inherit;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-align: inherit;
    transition-property: color, background-color;
    transition-duration: 0.2s;

    &:focus,
    &:hover,
    &:active {
      background: var(--ui-highlight-button-text-color);
      color: var(--ui-highlight-color);
      outline: 0;
    }
  }
}

.dropdown-menu__item--text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 4px 14px;
}

.dropdown-menu__item.edited-timestamp__history__item {
  border-bottom: 1px solid var(--ui-body-secondary-color);

  &:last-child {
    border-bottom: 0;
  }

  &.dropdown-menu__item--text,
  a,
  button {
    padding: 8px 14px;
    background: var(--ui-body-color);

    &:hover {
      background: var(--ui-hightlight-color);
    }
  }
}

.inline-account {
  display: inline-flex;
  align-items: center;
  vertical-align: top;

  .account__avatar {
    margin-inline-end: 5px;
    border-radius: 50%;
  }

  strong {
    font-weight: 600;
  }
}

.dropdown--active .dropdown__content {
  display: block;
  line-height: 1.1em;
  max-width: 311px;
  inset-inline-end: 0;
  text-align: start;
  z-index: 9999;

  & > ul {
    list-style: none;
    background: var(--ui-secondary-color);
    padding: 4px 0;
    border-radius: var(--ui-border-radius);
    box-shadow: 0 0 15px rgba($base-shadow-color, 0.4);
    min-width: 140px;
    position: relative;
  }

  &.dropdown__right {
    inset-inline-end: 0;
  }

  &.dropdown__left {
    & > ul {
      inset-inline-start: -98px;
    }
  }

  & > ul > li > a {
    font-size: 0.75rem;
    line-height: 1.1em;
    display: block;
    padding: 4px 14px;
    box-sizing: border-box;
    text-decoration: none;
    background: var(--ui-secondary-color);
    color: var(--ui-inverted-font-color);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    &:focus {
      outline: 0;
    }

    &:hover {
      background: var(--ui-highlight-color);
      color: var(--ui-highlight-button-text-color);
    }
  }
}

.dropdown__icon {
  vertical-align: middle;
}

.static-content {
  padding: 10px;
  padding-top: 20px;
  color: var(--ui-font-dimmer-color);

  h1 {
    font-size: 1rem;
    font-weight: 500;
    margin-bottom: 40px;
    text-align: center;
  }

  p {
    font-size: 0.85rem;
    margin-bottom: 20px;
  }
}

.column,
.drawer {
  flex: 1 1 100%;
  overflow: hidden;
}

@media screen and (width >= 631px) {
  .columns-area {
    padding: 0;
  }

  .column,
  .drawer {
    flex: 0 0 auto;
    padding: 10px 0 0;
    padding-inline-start: 5px;
    padding-inline-end: 5px;

    &:first-child {
      padding-inline-start: 10px;
    }

    &:last-child {
      padding-inline-end: 10px;
    }
  }

  .columns-area > div {
    .column,
    .drawer {
      padding-inline-start: 5px;
      padding-inline-end: 5px;
    }
  }
}

.tabs-bar {
  box-sizing: border-box;
  display: flex;
  background: var(--ui-base-lighter-color);
  flex: 0 0 auto;
  overflow-y: auto;
}

.tabs-bar__link {
  display: block;
  flex: 1 1 auto;
  padding: 15px 10px;
  padding-bottom: 13px;
  color: var(--ui-font-color);
  text-decoration: none;
  text-align: center;
  font-size: 0.9rem;
  font-weight: 500;
  border-bottom: 2px solid var(--ui-base-lighter-color);
  transition: all 50ms linear;
  transition-property: border-bottom, background, color;

  .fa {
    font-weight: 400;
    font-size: 1rem;
  }

  &:hover,
  &:focus,
  &:active {
    @include multi-columns('screen and (min-width: 631px)') {
      background: var(--ui-base-lighter-color);
      border-bottom-color: var(--ui-base-lighter-color);
    }
  }

  &.active {
    border-bottom: 2px solid var(--ui-highlight-color);
    color: var(--ui-highlight-color);
  }

  span {
    margin-inline-start: 5px;
    display: none;
  }

  span.icon {
    margin-inline-start: 0;
    display: inline;
  }
}

.icon-with-badge {
  position: relative;

  &__badge {
    position: absolute;
    inset-inline-start: 8px;
    top: -13px;
    background: var(--ui-highlight-color);
    padding: 1px 6px;
    border-radius: var(--ui-border-radius);
    font-size: 0.7rem;
    font-weight: 500;
    line-height: 1em;
    color: var(--ui-highlight-button-text-color);
  }

  &__issue-badge {
    position: absolute;
    inset-inline-start: 11px;
    bottom: 1px;
    display: block;
    background: $error-red;
    border-radius: 50%;
    width: 0.625rem;
    height: 0.625rem;
  }
}

.scrollable {
  overflow-y: scroll;
  overflow-x: hidden;
  flex: 1 1 auto;
  -webkit-overflow-scrolling: touch;

  &.optionally-scrollable {
    overflow-y: auto;
  }

  @supports (display: grid) {
    // hack to fix Chrome <57
    contain: strict;
  }

  &--flex {
    display: flex;
    flex-direction: column;
  }

  &__append {
    flex: 1 1 auto;
    position: relative;
    min-height: 120px;
  }

  .scrollable {
    flex: 1 1 auto;
  }
}

.scrollable-list--flex {
  display: flex;
  flex-direction: column;
}

.scrollable.fullscreen {
  @supports (display: grid) {
    // hack to fix Chrome <57
    contain: none;
  }
}

.react-toggle {
  display: inline-block;
  position: relative;
  cursor: pointer;
  background-color: transparent;
  border: 0;
  padding: 0;
  user-select: none;
  -webkit-tap-highlight-color: rgba($base-overlay-background, 0);
  -webkit-tap-highlight-color: transparent;
}

.react-toggle-screenreader-only {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.react-toggle--disabled {
  cursor: not-allowed;
  opacity: 0.5;
  transition: opacity 0.25s;
}

.react-toggle-track {
  width: 50px;
  height: 24px;
  padding: 0;
  border-radius: 30px;
  background-color: var(--ui-page-background);
  transition: background-color 0.2s ease;
}

.react-toggle:is(:hover, :focus-within):not(.react-toggle--disabled)
  .react-toggle-track {
  background-color: var(--ui-body-secondary-color);
}

.react-toggle--checked .react-toggle-track {
  background-color: var(--ui-highlight-color);
}

.react-toggle--checked:is(:hover, :focus-within):not(.react-toggle--disabled)
  .react-toggle-track {
  background-color: var(--ui-highlight-color);
}

.react-toggle-track-check {
  position: absolute;
  width: 14px;
  height: 10px;
  top: 0;
  bottom: 0;
  margin-top: auto;
  margin-bottom: auto;
  line-height: 0;
  inset-inline-start: 8px;
  opacity: 0;
  transition: opacity 0.25s ease;
}

.react-toggle--checked .react-toggle-track-check {
  opacity: 1;
  transition: opacity 0.25s ease;
}

.react-toggle-track-x {
  position: absolute;
  width: 10px;
  height: 10px;
  top: 0;
  bottom: 0;
  margin-top: auto;
  margin-bottom: auto;
  line-height: 0;
  inset-inline-end: 10px;
  opacity: 1;
  transition: opacity 0.25s ease;
}

.react-toggle--checked .react-toggle-track-x {
  opacity: 0;
}

.react-toggle-thumb {
  position: absolute;
  top: 1px;
  inset-inline-start: 1px;
  width: 22px;
  height: 22px;
  border: 1px solid var(--ui-base-color);
  border-radius: 50%;
  background-color: var(--ui-body-simple-bg);
  box-sizing: border-box;
  @media screen and (prefers-reduced-motion: no-preference) {
    & {
      transition: all 0.25s ease;
      transition-property: border-color, left;
    }
  }
}

.react-toggle--checked .react-toggle-thumb {
  inset-inline-start: 27px;
  border-color: var(--ui-highlight-color);
}

.getting-started__wrapper,
.getting_started,
.flex-spacer {
  background: var(--ui-base-color);
}

.getting-started__wrapper {
  position: relative;
  overflow-y: auto;
}

.flex-spacer {
  flex: 1 1 auto;
}

.getting-started {
  background: var(--ui-base-color);
  flex: 1 0 auto;

  p {
    color: var(--ui-font-secondary-color);
  }

  a {
    color: var(--ui-font-dimmer-color);
  }

  &__trends {
    flex: 0 1 auto;
    opacity: 1;
    animation: fade 150ms linear;
    margin-top: 10px;

    h4 {
      border-bottom: 1px solid var(--ui-base-lighter-color);
      padding: 10px;
      font-size: 0.75rem;
      text-transform: uppercase;
      font-weight: 500;

      a {
        color: var(--ui-font-dimmest-color);
        text-decoration: none;
      }
    }

    @media screen and (height <= 810px) {
      .trends__item:nth-of-type(3) {
        display: none;
      }
    }

    @media screen and (height <= 720px) {
      .trends__item:nth-of-type(2) {
        display: none;
      }
    }

    @media screen and (height <= 670px) {
      display: none;
    }

    .trends__item {
      border-bottom: 0;
      padding: 10px;

      &__current {
        color: var(--ui-font-dimmest-color);
      }
    }
  }
}

.column-link__badge {
  display: inline-block;
  border-radius: var(--ui-border-radius);
  font-size: 0.75rem;
  line-height: 1.3;
  font-weight: 500;
  padding: 4px 8px;
  margin: -6px 10px;
  -webkit-text-stroke: 0;
}

.keyboard-shortcuts {
  padding: 8px 0 0;
  overflow: hidden;

  thead {
    position: absolute;
    inset-inline-start: -9999px;
  }

  td {
    padding: 0 10px 8px;
  }

  kbd {
    display: inline-block;
    padding: 3px 5px;
    background-color: var(--ui-base-lighter-color);
    border: 1px solid var(--ui-base-color);
  }
}

.setting-text {
  color: var(--ui-font-dimmest-color);
  background: transparent;
  border: 0;
  border-bottom: 2px solid var(--ui-primary-color);
  outline: 0;
  box-sizing: border-box;
  display: block;
  font-family: inherit;
  margin-bottom: 10px;
  padding: 7px 0;
  width: 100%;

  &:focus,
  &:active {
    color: var(--ui-font-color);
    border-bottom-color: var(--ui-highlight-color);
  }

  @include limited-single-column('screen and (max-width: 600px)') {
    font-size: 1rem;
  }

  &.light {
    color: var(--ui-inverted-font-color);
    border-bottom: 2px solid var(--ui-primary-color);

    &:focus,
    &:active {
      color: var(--ui-inverted-font-color);
      border-bottom-color: var(--ui-highlight-color);
    }
  }
}

button.icon-button i.fa-retweet {
  mask-position: 0 0;
  height: 1em;
  transition:
    mask-position 0.9s steps(10),
    background 0.2s;
  transition-duration: 0s;
  width: 1.5em;

  &::before {
    display: none !important;
  }
}

button.icon-button.active i.fa-retweet {
  transition-duration: 0.9s;
  mask-position: 0 90%;
}

@media screen and (prefers-reduced-motion) {
  button.icon-button i.fa-retweet,
  button.icon-button.active i.fa-retweet {
    transition: none;
  }
}

@media screen and (prefers-reduced-motion) {
  button.icon-button.disabled i.fa-retweet {
    color: var(--ui-highlight-color);
  }
}

.load-more {
  display: block;
  color: var(--ui-highlight-color);
  border: 3px solid var(--ui-highlight-color);
  font-size: 1rem;
  text-align: center;
  padding: 10px 15px;
  box-sizing: border-box;
  clear: both;
  text-decoration: none;
  background: transparent;
  margin: 0 auto;
  border-radius: 100px;
  margin-top: 8px;
  font-weight: bold;
  transition:
    0.2s background,
    0.2s color,
    0.2s border-color;

  &:hover {
    background: var(--ui-highlight-color);
    color: var(--ui-highlight-button-text-color);
  }
}

.timeline-hint {
  text-align: center;
  color: var(--ui-font-dimmest-color);
  padding: 15px;
  box-sizing: border-box;
  width: 100%;
  cursor: default;

  strong {
    font-weight: 500;
  }

  a {
    color: var(--ui-highlight-color);
    text-decoration: none;

    &:hover,
    &:focus,
    &:active {
      text-decoration: underline;
      color: var(--ui-highlight-color);
    }
  }
}

.missing-indicator {
  padding-top: 20px + 48px;

  .regeneration-indicator__figure {
    background-image: url('~flavours/aether/images/elephant_ui_disappointed.svg');
  }
}

.scrollable > div > :first-child .notification__dismiss-overlay > .wrappy {
  border-top: 1px solid var(--ui-base-color);
}

.notification__dismiss-overlay {
  overflow: hidden;
  position: absolute;
  top: 0;
  inset-inline-end: 0;
  bottom: -1px;
  padding-inline-start: 15px; // space for the box shadow to be visible
  z-index: 999;
  align-items: center;
  justify-content: flex-end;
  cursor: pointer;
  display: flex;

  .wrappy {
    width: $dismiss-overlay-width;
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: var(--ui-base-lighter-color);
    border-inline-start: 1px solid var(--ui-secondary-color);
    box-shadow: 0 0 5px black;
    border-bottom: 1px solid var(--ui-base-color);
  }

  .ckbox {
    border: 2px solid var(--ui-primary-color);
    border-radius: 2px;
    width: 30px;
    height: 30px;
    font-size: 1.25rem;
    color: var(--ui-font-dimmest-color);
    text-shadow: 0 0 5px black;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &:focus {
    outline: 0 !important;

    .ckbox {
      box-shadow: 0 0 1px 1px var(--ui-highlight-color);
    }
  }
}

.text-btn {
  display: inline-block;
  padding: 0;
  font-family: inherit;
  font-size: inherit;
  color: inherit;
  border: 0;
  background: transparent;
  cursor: pointer;
}

.loading-indicator {
  color: var(--ui-font-dimmer-color);
  font-size: 0.75rem;
  font-weight: 400;
  text-transform: uppercase;
  overflow: visible;
  position: absolute;
  top: 50%;
  inset-inline-start: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
}

.circular-progress {
  color: var(--ui-primary-color);
  animation: 1.4s linear 0s infinite normal none running simple-rotate;

  circle {
    stroke: currentColor;
    stroke-dasharray: 80px, 200px;
    stroke-dashoffset: 0;
    animation: circular-progress 1.4s ease-in-out infinite;
  }
}

@keyframes circular-progress {
  0% {
    stroke-dasharray: 1px, 200px;
    stroke-dashoffset: 0;
  }

  50% {
    stroke-dasharray: 100px, 200px;
    stroke-dashoffset: -15px;
  }

  100% {
    stroke-dasharray: 100px, 200px;
    stroke-dashoffset: -125px;
  }
}

@keyframes simple-rotate {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes spring-rotate-in {
  0% {
    transform: rotate(0deg);
  }

  30% {
    transform: rotate(-484.8deg);
  }

  60% {
    transform: rotate(-316.7deg);
  }

  90% {
    transform: rotate(-375deg);
  }

  100% {
    transform: rotate(-360deg);
  }
}

@keyframes spring-rotate-out {
  0% {
    transform: rotate(-360deg);
  }

  30% {
    transform: rotate(124.8deg);
  }

  60% {
    transform: rotate(-43.27deg);
  }

  90% {
    transform: rotate(15deg);
  }

  100% {
    transform: rotate(0deg);
  }
}

.spoiler-button {
  top: 0;
  inset-inline-start: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 100;

  &--minified {
    display: flex;
    inset-inline-start: 4px;
    top: 4px;
    width: auto;
    height: auto;
    align-items: center;
  }

  &--click-thru {
    pointer-events: none;
  }

  &--hidden {
    display: none;
  }

  &__overlay {
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba($black, 0.5);
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
    border: 0;

    &__label {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 8px;
      flex-direction: column;
      color: #fff; // Hardcoded color
      font-weight: 500;
      font-size: 0.9rem;
      font-family: roboto, sans-serif;
    }

    &__action {
      font-weight: 400;
      font-size: 0.85rem;
    }
  }
}

.setting-toggle {
  display: block;
  line-height: 1.5em;
}

.setting-toggle__label,
.setting-meta__label {
  color: var(--ui-font-dimmest-color);
  display: inline-block;
  margin-bottom: 14px;
  margin-inline-start: 8px;
  vertical-align: middle;
}

.column-settings__row .radio-button {
  display: block;
}

.setting-meta__label {
  float: right;
}

@keyframes heartbeat {
  0% {
    transform: scale(1);
    transform-origin: center center;
    animation-timing-function: ease-out;
  }

  10% {
    transform: scale(0.91);
    animation-timing-function: ease-in;
  }

  17% {
    transform: scale(0.98);
    animation-timing-function: ease-out;
  }

  33% {
    transform: scale(0.87);
    animation-timing-function: ease-in;
  }

  45% {
    transform: scale(1);
    animation-timing-function: ease-out;
  }
}

.pulse-loading {
  animation: heartbeat 1.5s ease-in-out infinite both;
}

.upload-area {
  align-items: center;
  background: rgba($base-overlay-background, 0.8);
  display: flex;
  height: 100vh;
  justify-content: center;
  inset-inline-start: 0;
  opacity: 0;
  position: fixed;
  top: 0;
  visibility: hidden;
  width: 100vw;
  z-index: 2000;

  * {
    pointer-events: none;
  }
}

.upload-area__drop {
  width: 320px;
  height: 160px;
  display: flex;
  box-sizing: border-box;
  position: relative;
  padding: 8px;
}

.upload-area__background {
  position: absolute;
  top: 0;
  inset-inline-end: 0;
  bottom: 0;
  inset-inline-start: 0;
  z-index: -1;
  border-radius: var(--ui-border-radius);
  background: var(--ui-base-color);
  box-shadow: 0 0 5px rgba($base-shadow-color, 0.2);
}

.upload-area__content {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: var(--ui-font-secondary-color);
  font-size: 1.1rem;
  font-weight: 500;
  border: 2px dashed var(--ui-base-lighter-color);
  border-radius: var(--ui-border-radius);
}

.dropdown--active .emoji-button img {
  opacity: 1;
  filter: none;
}

.loading-bar {
  background-color: var(--ui-highlight-color);
  height: 3px;
  position: fixed;
  top: 0;
  inset-inline-start: 0;
  z-index: 9999;
}

.icon-badge-wrapper {
  position: relative;
}

.icon-badge {
  position: absolute;
  display: block;
  inset-inline-end: -0.25em;
  top: -0.25em;
  background-color: var(--ui-highlight-color);
  border-radius: 50%;
  font-size: 75%;
  width: 1em;
  height: 1em;
}

.conversation {
  display: flex;
  border-bottom: 1px solid var(--ui-base-lighter-color);
  padding: 5px;
  padding-bottom: 0;

  &:focus {
    background: var(--ui-base-lighter-color);
    outline: 0;
  }

  &__avatar {
    flex: 0 0 auto;
    padding: 10px;
    padding-top: 12px;
    position: relative;
    cursor: pointer;
  }

  &__unread {
    display: inline-block;
    background: var(--ui-highlight-color);
    border-radius: 50%;
    width: 0.625rem;
    height: 0.625rem;
    margin: -0.1ex 0.15em 0.1ex;
  }

  &__content {
    flex: 1 1 auto;
    padding: 10px 5px;
    padding-inline-end: 15px;
    overflow: hidden;

    &__info {
      overflow: hidden;
      display: flex;
      flex-direction: row-reverse;
      justify-content: space-between;
    }

    &__relative-time {
      font-size: 0.95rem;
      color: var(--ui-font-dimmest-color);
      padding-inline-start: 15px;
    }

    &__names {
      color: var(--ui-font-dimmest-color);
      font-size: 0.95rem;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-bottom: 4px;
      flex-basis: 90px;
      flex-grow: 1;

      a {
        color: var(--ui-font-color);
        text-decoration: none;

        &:hover,
        &:focus,
        &:active {
          text-decoration: underline;
        }
      }
    }

    .status__content {
      margin: 0;
    }
  }

  &--unread {
    background: var(--ui-base-color);

    &:focus {
      background: var(--ui-base-lighter-color);
    }

    .conversation__content__info {
      font-weight: 700;
    }

    .conversation__content__relative-time {
      color: var(--ui-font-color);
    }
  }
}

.ui .flash-message {
  margin-top: 10px;
  margin-inline-start: auto;
  margin-inline-end: auto;
  margin-bottom: 0;
  min-width: 75%;
}

::-webkit-scrollbar-thumb {
  border-radius: 0;
}

noscript {
  text-align: center;

  img {
    width: 200px;
    opacity: 0.5;
    animation: flicker 4s infinite;
  }

  div {
    font-size: 0.9rem;
    margin: 30px auto;
    color: var(--ui-font-secondary-color);
    max-width: 400px;

    a {
      color: var(--ui-highlight-color);
      text-decoration: underline;

      &:hover {
        text-decoration: none;
      }
    }

    a {
      word-break: break-word;
    }
  }
}

@keyframes flicker {
  0% {
    opacity: 1;
  }

  30% {
    opacity: 0.75;
  }

  100% {
    opacity: 1;
  }
}

.navbutton {
  text-decoration: none;
  display: block;
  width: 80px;
  margin: 0 auto;

  > div {
    text-align: center;
    text-decoration: none;
    color: var(--ui-highlight-color);
    font-size: 0.9rem;
    padding: 0.3rem;
    font-weight: 800;
    margin: 0 auto;
    border-radius: 100px;
    transition:
      background 0.2s,
      color 0.2s,
      border-color 0.2s;
    border: 2px solid;
    margin-top: 20px;

    &:hover {
      background: var(--ui-highlight-color);
      border-color: var(--ui-highlight-color);
      color: var(--ui-highlight-button-text-color);
    }
  }
}

.media-modal__overlay .picture-in-picture__footer {
  align-items: center;
  justify-content: center;
  display: flex;
}
