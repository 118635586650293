.scrollable .account-card {
  margin: 10px;
  background: var(--ui-base-color);
}

.scrollable .account-card__title__avatar {
  img,
  .account__avatar {
    border-color: var(--ui-base-color);
  }
}

.scrollable .account-card__bio::after {
  background: linear-gradient(to left, var(--ui-base-color), transparent);
}

.filter-form {
  background: var(--ui-base-color);

  &__column {
    padding: 10px 15px;
    padding-bottom: 0;
  }

  .radio-button {
    display: block;
  }
}

.radio-button {
  font-size: 0.9rem;
  position: relative;
  display: inline-block;
  padding: 6px 0;
  line-height: 1.1em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;

  input[type='radio'],
  input[type='checkbox'] {
    display: none;
  }

  &__input {
    display: inline-block;
    position: relative;
    border: 1px solid var(--ui-primary-color);
    box-sizing: border-box;
    width: 18px;
    height: 18px;
    flex: 0 0 auto;
    margin-inline-end: 10px;
    top: -1px;
    border-radius: 50%;
    vertical-align: middle;

    &.checked {
      border-color: var(--ui-highlight-color);
      background: var(--ui-highlight-color);
    }
  }
}
