.tenor-modal {
  width: 500px;
  box-shadow: 0 transparent;

  .modal_bottom {
    display: flex;
    justify-content: space-between;
    align-items: end;

    button {
      align-self: start;
    }

    .modal_logo {
      text-align: right;

      img {
        width: 2.5rem;
      }
    }
  }
}

.tenor-modal__container {
  text-align: center;
  padding: 20px;
}

.tenor-modal__container .icon-button {
  margin: 10px;
}

.react-tenor--search {
  box-sizing: border-box;
  color: var(--ui-font-bright-color);
  background: transparent;
  border: 0;
  border-bottom: 2px solid var(--ui-font-bright-color);
  font-size: 1.5em;
  margin-bottom: 10px;
  line-height: 1.3;
  overflow: visible;
  padding: 10px;
  width: 100%;
  -webkit-appearance: none;
  transition:
    color 0.2s,
    border-color 0.2s;

  &:focus,
  &:active {
    color: var(--ui-font-color);
    border-color: var(--ui-font-color);
    outline: none;
  }
}

.react-tenor--search[type='search']::-webkit-search-cancel-button,
.react-tenor--search[type='search']::-webkit-search-decoration {
  -webkit-appearance: none;
  appearance: none;
  color: var(--ui-font-bright-color);
  border-color: var(--ui-font-bright-color);
}

.react-tenor--autocomplete {
  display: none;
}

.react-tenor--autocomplete span {
  visibility: hidden;
}

.react-tenor--spinner {
  display: none;
}

.react-tenor--suggestions {
  display: none;
}

.react-tenor--results {
  display: flex;
  flex-wrap: wrap;
  position: relative;
}

.react-tenor--result {
  background: rgba(87, 87, 131, 15%);
  border: 0;
  cursor: pointer;
  display: inline-block;
  flex-basis: 25%;
  height: 120px;
  opacity: 1;
  padding: 0;
  transition: opacity 0.3s;
  width: 120px;
}

.react-tenor--result span {
  animation: react-tenor-fade-in 0.2s;
  background-size: cover;
  display: block;
  height: 100%;
  width: 100%;
}

.react-tenor--result:focus {
  box-shadow: 0 0 2px 2px #6a89af;
  border: 1px solid #f7f7f7;
  outline: none;
  z-index: 1;
}

.react-tenor--result:hover {
  opacity: 0.5;
}

@media screen and (width <= 480px) {
  .react-tenor--result {
    flex-basis: 33%;
  }
}

.react-tenor--page-left,
.react-tenor--page-right {
  width: 40px;
  height: 40px;
  border: 3px solid var(--ui-highlight-color);
  border-radius: 100%;
  color: var(--ui-highlight-color);
  font-size: 1.5rem;
  -webkit-text-stroke: 1px var(--ui-highlight-color);
  transition:
    color 0.2s,
    -webkit-text-stroke 0.2s,
    background 0.2s,
    border-color 0.2s;
  background: transparent;
  margin: 10px auto;

  &:hover {
    color: var(--ui-font-color);
    -webkit-text-stroke: 1px var(--ui-font-color);
    border-color: var(--ui-font-color);
  }
}

.react-tenor--page-left::after {
  content: '←';
}

.react-tenor--page-right::after {
  content: '→';
}

@keyframes react-tenor-fade-in {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes react-tenor-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}
