.dashboard__counters {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -5px;
  margin-bottom: 20px;

  & > div {
    box-sizing: border-box;
    flex: 0 0 33.333%;
    padding: 0 5px;
    margin-bottom: 10px;

    & > div,
    & > a {
      padding: 20px;
      background: var(--ui-base-color);
      border-radius: 4px;
      box-sizing: border-box;
      height: 100%;
    }

    & > a {
      text-decoration: none;
      color: inherit;
      display: block;

      &:hover,
      &:focus,
      &:active {
        background: var(--ui-body-color);
      }
    }
  }

  &__num,
  &__text {
    text-align: center;
    font-weight: 500;
    font-size: 1.5rem;
    color: var(--ui-font-color);
    margin-bottom: 20px;
    line-height: 1.8em;
  }

  &__text {
    font-size: 1.1rem;
  }

  &__label {
    font-size: 0.8rem;
    color: var(--ui-font-dimmest-color);
    text-align: center;
    font-weight: 500;
  }
}

.dashboard {
  display: grid;
  grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
  grid-gap: 10px;

  @media screen and (width <= 1350px) {
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
  }

  &__item {
    &--span-double-column {
      grid-column: span 2;
    }

    &--span-double-row {
      grid-row: span 2;
    }

    h4 {
      padding-top: 20px;
    }
  }

  &__quick-access {
    display: flex;
    align-items: baseline;
    border-radius: 4px;
    background: var(--ui-highlight-color);
    color: var(--ui-font-color);
    transition: all 100ms ease-in;
    font-size: 0.8rem;
    padding: 0 16px;
    line-height: 1.9em;
    text-decoration: none;
    margin-bottom: 4px;

    &:active,
    &:focus,
    &:hover {
      background-color: var(--ui-highlight-color);
      transition: all 200ms ease-out;
    }

    &.positive {
      background: var(--ui-base-color);
      color: $valid-value-color;
    }

    &.negative {
      background: var(--ui-base-color);
      color: $error-value-color;
    }

    span {
      flex: 1 1 auto;
    }

    .fa {
      flex: 0 0 auto;
    }

    strong {
      font-weight: 700;
    }
  }
}

.layout-multiple-columns {
  .column-header__collapsible {
    overflow-y: scroll;
    max-height: calc(100vh - 5rem);
  }

  .account__fields {
    margin-top: 8px;
  }

  .dismissable-banner {
    margin-top: 8px;
    margin-bottom: 8px;
  }

  .home-scroll .compose-form,
  .fixed_wrapper {
    display: none;
  }

  .column_explore,
  .scrollable--flex {
    flex: 1;
    display: flex;
    flex-direction: column;
  }

  .column_explore .account__section-headline {
    display: none;
  }

  .column-header {
    border-bottom: 2px solid var(--ui-highlight-color);
    padding-bottom: 8px;

    > button {
      font-size: 1.2rem;
    }

    .column_explore .account__section-headline {
      display: none;
    }
  }

  .drawer__header .icon-badge {
    background-color: var(--ui-highlight-button-text-color);
    border: 1px solid var(--ui-highlight-color);
    width: 0.8em;
    height: 0.8em;
    transition:
      color 0.2s,
      border 0.2s;
  }

  .drawer__header > a:hover {
    color: var(--ui-font-color);
  }

  .drawer__header > a:hover .icon-badge {
    background-color: var(--ui-highlight-color);
  }

  .compose-form__bottom-buttons {
    &.spoiler-button--clicked {
      flex-flow: row wrap;
    }

    .spoiler_wrapper {
      .spoiler-input {
        padding-bottom: 0;

        &.spoiler-input--visible {
          flex: 1 100%;
          padding-bottom: 12px;
        }
      }
    }
  }
}
