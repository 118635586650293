.modal-layout {
  background: #282c37;
  display: flex;
  flex-direction: column;
  height: 100vh;
  padding: 0;
}

.modal-layout__mastodon {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: flex-end;

  > div {
    flex: 1;
    max-height: 235px;
    position: relative;

    img {
      max-height: 100%;
      max-width: 100%;
      height: 100%;
      position: absolute;
      bottom: 0;
      inset-inline-start: 0;
    }
  }
}

@media screen and (width <= 600px) {
  .account-header {
    margin-top: 0;
  }
}

.trending_hashtags-modal {
  width: 500px;
  max-height: calc(100vh - 8px);
  overflow-y: auto;
  box-sizing: border-box;

  .explore__links {
    width: 500px;
    margin: 0 auto;
  }
}

.suggestions-modal,
.compose-modal {
  width: var(--ui-main-column-width);
  max-height: calc(100vh - 20px);
  overflow-y: auto;
  box-sizing: border-box;

  .account-card {
    margin-top: 20px;

    &:first-child {
      margin-top: 0;
    }
  }
}

.compose-modal {
  position: relative;
  background: var(--ui-body-dark-color);
  padding: 8px;
  border-radius: var(--ui-border-radius);

  .compose-form {
    padding-bottom: 0;
  }

  .button-cancel {
    position: absolute;
    left: 8px;
    bottom: 8px;
    font-size: 1.2rem;
  }

  .spoiler-input.spoiler-input--visible {
    width: calc(100% - 9rem);
  }
}

.right-column-show-more {
  display: flex;
  justify-content: center;
  margin-top: 8px;
}

.trending_hashtags-modal,
.suggestions-modal {
  min-height: 200px;
  background: var(--ui-body-secondary-color);
  border-radius: 8px;
  padding: 12px;
}

@media screen and (max-width: $no-gap-breakpoint) {
  .trending_hashtags-modal,
  .suggestions-modal,
  .compose-modal,
  .compose-form {
    width: calc(100vw - 20px);

    .explore__links {
      width: auto;
    }
  }
}
