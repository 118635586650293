.emojione {
  font-size: inherit;
  vertical-align: middle;
  object-fit: contain;
  margin: -0.2ex 0.15em 0.2ex;
  width: 22px;
  height: 22px;

  img {
    width: auto;
  }
}

.emoji-picker-dropdown__menu {
  background: var(--ui-body-color);
  box-shadow: 4px 4px 6px rgba($base-shadow-color, 0.4);
  border-radius: 4px;
  margin-top: 5px;
  z-index: 2;
  position: absolute;

  .emoji-mart-scroll {
    transition: opacity 200ms ease;
  }

  &.selecting .emoji-mart-scroll {
    opacity: 0.5;
  }
}

.emoji-picker-dropdown__modifiers {
  position: absolute;
  top: 60px;
  inset-inline-end: 11px;
  cursor: pointer;
}

.emoji-picker-dropdown__modifiers__menu {
  position: absolute;
  z-index: 4;
  top: -4px;
  inset-inline-start: -8px;
  background: var(--ui-body-secondary-color);
  border-radius: 4px;
  box-shadow: 1px 2px 6px rgba($base-shadow-color, 0.2);
  overflow: hidden;

  button {
    display: block;
    cursor: pointer;
    border: 0;
    padding: 4px 8px;
    background: transparent;

    &:hover,
    &:focus,
    &:active {
      background: var(--ui-font-color);
    }
  }

  .emoji-mart-emoji {
    height: 44px;
  }
}

.emoji-mart-emoji {
  span {
    background-repeat: no-repeat;
  }
}

.emoji-button {
  display: block;
  padding-top: 5px;
  padding-bottom: 2px;
  padding-inline-start: 2px;
  padding-inline-end: 5px;
  outline: 0;
  cursor: pointer;

  &:active,
  &:focus {
    outline: 0 !important;
  }

  img {
    filter: grayscale(100%);
    opacity: 0.8;
    display: block;
    margin: 0;
    width: 22px;
    height: 22px;
  }

  &:hover,
  &:active,
  &:focus {
    img {
      opacity: 1;
      filter: none;
    }
  }
}

/* Emoji Picker */

.emoji-mart {
  &,
  * {
    box-sizing: border-box;
    line-height: 1.15;
  }

  font-size: 0.85rem;
  display: inline-block;
  color: var(--ui-inverted-font-color);

  .emoji-mart-emoji {
    padding: 6px;
  }
}

.emoji-mart-bar {
  border: 0 solid var(--ui-body-secondary-color);

  &:first-child {
    border-bottom-width: 1px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    background: var(--ui-body-secondary-color);
  }

  &:last-child {
    border-top-width: 1px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    display: none;
  }
}

.emoji-mart-anchors {
  display: flex;
  justify-content: space-between;
  padding: 0 6px;
  color: var(--ui-font-color);
  line-height: 0;
}

.emoji-mart-anchor {
  position: relative;
  flex: 1;
  text-align: center;
  padding: 12px 4px;
  overflow: hidden;
  transition: color 0.1s ease-out;
  cursor: pointer;
  background: transparent;
  border: 0;
  color: var(--ui-font-color);

  &:hover {
    color: var(--ui-font-secondary-color);
  }
}

.emoji-mart-anchor-selected {
  color: var(--ui-highlight-color);

  &:hover {
    color: var(--ui-highlight-color);
  }

  .emoji-mart-anchor-bar {
    bottom: 0;
  }
}

.emoji-mart-anchor-bar {
  position: absolute;
  bottom: -3px;
  inset-inline-start: 0;
  width: 100%;
  height: 3px;
  background-color: var(--ui-highlight-color);
}

.emoji-mart-anchors {
  i {
    display: inline-block;
    width: 100%;
    max-width: 22px;
  }

  svg {
    fill: currentColor;
    max-height: 18px;
  }
}

.emoji-mart-scroll {
  overflow-y: scroll;
  height: 340px;
  max-height: 35vh;
  padding: 0 6px 6px;
  background: var(--ui-body-color);
  will-change: transform;

  &::-webkit-scrollbar-track:hover,
  &::-webkit-scrollbar-track:active {
    background-color: rgba($base-overlay-background, 0.3);
  }
}

.emoji-mart-search {
  padding: 10px;
  padding-inline-end: 45px;
  background: var(--ui-body-color);
  position: relative;

  input {
    font-size: 1rem;
    font-weight: 400;
    padding: 7px 9px;
    padding-inline-end: 25px;
    font-family: inherit;
    display: block;
    width: 100%;
    background: var(--ui-body-color);
    color: var(--ui-font-secondary-color);
    border: 1px solid var(--ui-page-background);
    border-radius: 4px;

    &::-moz-focus-inner {
      border: 0;
    }

    &::-moz-focus-inner,
    &:focus,
    &:active {
      outline: 0 !important;
    }

    &::-webkit-search-cancel-button {
      display: none;
    }
  }
}

.emoji-mart-search-icon {
  position: absolute;
  top: 18px;
  inset-inline-end: 45px + 5px;
  z-index: 2;
  padding: 2px 5px 1px;
  border: 0;
  background: none;
  transition: all 100ms linear;
  transition-property: opacity;
  pointer-events: auto;
  opacity: 0.7;

  &:disabled {
    cursor: default;
    pointer-events: none;

    svg {
      fill: var(--ui-font-secondary-color);
    }
  }

  svg {
    fill: var(--ui-font-color);
  }
}

.emoji-mart-category .emoji-mart-emoji {
  cursor: pointer;

  span {
    z-index: 1;
    position: relative;
    text-align: center;
  }

  &:hover::before {
    content: '';
    position: absolute;
    top: 0;
    inset-inline-start: 0;
    width: 100%;
    height: 100%;
    background-color: var(--ui-body-secondary-color);
    border-radius: 100%;
    z-index: -1;
  }
}

.emoji-mart-category-label {
  z-index: 2;
  position: relative;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  color: var(--ui-font-color);
  font-size: 0.9rem;

  span {
    display: block;
    width: 100%;
    font-weight: 500;
    padding: 5px 6px;
    background: var(--ui-body-color);
  }
}

/* For screenreaders only, via https://stackoverflow.com/a/19758620 */
.emoji-mart-sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

.emoji-mart-category-list {
  margin: 0;
  padding: 0;
}

.emoji-mart-category-list li {
  list-style: none;
  margin: 0;
  padding: 0;
  display: inline-block;
}

.emoji-mart-emoji {
  position: relative;
  display: inline-block;
  background: transparent;
  border: 0;
  padding: 0;
  font-size: 0;

  span {
    width: 40px;
    height: 40px;
  }
}

.emoji-mart-no-results {
  font-size: 0.9rem;
  color: var(--ui-font-color);
  text-align: center;
  padding: 5px 6px;
  padding-top: 70px;

  .emoji-mart-no-results-label {
    margin-top: 0.2em;
  }

  .emoji-mart-emoji:hover::before {
    cursor: default;
    content: none;
  }
}

.emoji-mart-preview {
  display: none;
}
