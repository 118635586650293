.home-scroll .compose-form {
  display: none;
}

.column__wrapper {
  display: flex;
  flex: 1 1 auto;
  position: relative;
}

.columns-area {
  display: flex;
  flex: 1 1 auto;
  flex-direction: row;
  justify-content: flex-start;
  overflow-x: auto;
  position: relative;

  &__panels {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100%;
    min-height: 100vh;

    &__pane {
      height: 100%;
      overflow: hidden;
      pointer-events: none;
      display: flex;
      justify-content: flex-end;
      min-width: 285px;

      &--start {
        justify-content: flex-start;
      }

      &__inner {
        position: fixed;
        width: 285px;
        pointer-events: auto;
        height: 100%;
      }
    }

    &__main {
      box-sizing: border-box;
      width: 100%;
      flex: 0 0 auto;
      display: flex;
      flex-direction: column;

      @media screen and (min-width: $no-gap-breakpoint) {
        padding: 0 10px;
        max-width: 600px;
      }
    }
  }
}

$ui-header-height: 55px;

.ui__header {
  display: none;
  box-sizing: border-box;
  height: $ui-header-height;
  top: 0;
  z-index: 3;
  justify-content: space-between;
  align-items: center;

  &__logo {
    display: inline-flex;
    padding-right: 15px;

    .logo {
      height: $ui-header-height - 30px;
      width: auto;
    }

    .logo--wordmark {
      display: none;
    }

    @media screen and (width >= 320px) {
      .logo--wordmark {
        display: block;
      }

      .logo--icon {
        display: none;
      }
    }
  }

  &__links {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 0 10px;

    .button {
      flex: 0 0 auto;
    }
  }
}

.tabs-bar__wrapper {
  top: $ui-header-height;
  padding-top: 0;

  @media screen and (min-width: $no-gap-breakpoint) {
    padding-top: 10px;
    top: 0;
  }

  .tabs-bar {
    margin-bottom: 0;

    @media screen and (min-width: $no-gap-breakpoint) {
      margin-bottom: 10px;
    }
  }
}

.react-swipeable-view-container {
  &,
  .columns-area,
  .column {
    height: 100%;
  }
}

.react-swipeable-view-container > * {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.column {
  width: 330px;
  position: relative;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}

.ui {
  flex: 0 0 auto;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.column {
  overflow: hidden;
}

.column-back-button {
  box-sizing: border-box;
  width: 100%;
  border-radius: 4px 4px 0 0;
  color: var(--ui-highlight-color);
  cursor: pointer;
  flex: 0 0 auto;
  font-size: 1rem;
  border: 0;
  text-align: unset;
  padding: 15px;
  margin: 0;
  z-index: 3;

  &:hover {
    text-decoration: underline;
  }
}

.column-header__back-button {
  background: var(--ui-base-color);
  border: 0;
  font-family: inherit;
  color: var(--ui-highlight-color);
  cursor: pointer;
  flex: 0 0 auto !important;
  padding: 0 !important;
  font-size: 1rem;
  padding-inline-end: 5px;
  z-index: 3;

  &:hover {
    text-decoration: underline;
  }

  &:last-child {
    padding: 0;
    padding-inline-end: 15px;
  }
}

.column-back-button__icon {
  display: inline-block;
  margin-inline-end: 5px;
}

.column-back-button--slim {
  position: relative;
}

.column-back-button--slim-button {
  cursor: pointer;
  flex: 0 0 auto;
  font-size: 1rem;
  padding: 15px;
  position: absolute;
  inset-inline-end: 0;
  top: -48px;
}

.column-link__icon {
  display: inline-block;
  margin-inline-end: 5px;
}

.column-subheading {
  background: var(--ui-base-color);
  color: var(--ui-font-dimmer-color);
  padding: 8px 20px;
  font-size: 0.75rem;
  font-weight: 500;
  text-transform: uppercase;
  cursor: default;
}

.column-header__wrapper {
  position: relative;
  flex: 0 0 auto;
  z-index: 1;

  &.active {
    &::before {
      display: block;
      content: '';
      position: absolute;
      bottom: -13px;
      inset-inline-start: 0;
      inset-inline-end: 0;
      margin: 0 auto;
      width: 60%;
      pointer-events: none;
      height: 28px;
      z-index: 1;
      opacity: 0.25;
      background: radial-gradient(
        ellipse,
        (var(--ui-highlight-color)) 0%,
        transparent 60%
      );
    }
  }

  .announcements {
    z-index: 1;
    position: relative;
  }
}

.column-header {
  display: flex;
  font-size: 1rem;
  border-radius: 4px 4px 0 0;
  flex: 0 0 auto;
  cursor: pointer;
  position: relative;
  z-index: 2;
  outline: 0;
  overflow: hidden;
  align-items: center;

  & > button {
    margin: 0;
    border: 0;
    padding: 15px;
    background: transparent;
    font: inherit;
    text-align: start;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    flex: 1;
    color: var(--ui-highlight-color);
    font-weight: bold;
    font-size: 2rem;
  }

  & > .column-header__back-button {
    color: var(--ui-highlight-color);
    -webkit-text-stroke: 0;
    font-size: 1.2rem;
  }

  &.active {
    .column-header__icon {
      color: var(--ui-highlight-color);
    }
  }

  &:focus,
  &:active {
    outline: 0;
  }
}

.column {
  width: 330px;
  position: relative;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  .wide .columns-area:not(.columns-area--mobile) & {
    flex: auto;
    min-width: 330px;
    max-width: var(--ui-main-column-width);
  }
}

.columns-area--mobile {
  overflow: visible;

  .column {
    overflow: visible;
  }
}

.column-header__buttons {
  height: 48px;
  display: flex;
  margin-inline-start: 0;

  > .active {
    color: var(--ui-font-color);
    background: transparent;
  }
}

.column-header__links {
  margin-bottom: 14px;
}

.column-header__links .text-btn {
  margin-inline-end: 10px;
}

.column-header__button {
  cursor: pointer;
  transition: color 0.2s;
  font-size: 1rem;
  border-radius: 100%;
  margin-right: 5px;
  color: var(--ui-highlight-color);
  border: 3px solid;
  background: transparent;
  width: 48px;
  height: 48px;

  &:disabled {
    color: var(--ui-font-dimmer-color);
    cursor: default;
  }
}

.column-header__notif-cleaning-buttons {
  display: flex;
  align-items: stretch;
  justify-content: space-around;

  button {
    border: 0;
    color: var(--ui-font-dimmest-color);
    cursor: pointer;
    border-bottom: 4px solid;
    transition: color 0.2s;
    border-radius: 0;
    width: auto;
    height: auto;
    margin-right: 0;

    &:hover {
      color: var(--ui-font-dimmest-color);
    }

    &.active {
      color: var(--ui-font-color);
      border-color: var(--ui-highlight-color);
      background: var(--ui-base-lighter-color);

      &:hover {
        color: var(--ui-font-color);
        background: var(--ui-base-lighter-color);
      }
    }

    //  glitch - added focus ring for keyboard navigation
    // &:focus {
    //   text-shadow: 0 0 4px var(--ui-font-color);
    // }

    &:disabled {
      color: var(--ui-font-dimmer-color);
      cursor: default;
    }

    background: transparent;
    text-align: center;
    padding: 10px 5px;
    font-size: 0.9rem;
  }

  b {
    font-weight: bold;
  }
}

.layout-single-column .column-header__notif-cleaning-buttons {
  @media screen and (min-width: $no-gap-breakpoint) {
    b,
    i {
      margin-inline-end: 5px;
    }

    br {
      display: none;
    }

    button {
      padding: 15px 5px;
    }
  }
}

// The notifs drawer with no padding to have more space for the buttons
.column-header__collapsible-inner.nopad-drawer {
  padding: 0;
}

.column-header__collapsible {
  max-height: 100rem;
  overflow: hidden;
  overflow-y: auto;
  color: var(--ui-font-dimmest-color);
  opacity: 1;
  z-index: 1;
  position: relative;

  @media screen and (prefers-reduced-motion: no-preference) {
    & {
      transition:
        max-height 150ms ease-in-out,
        opacity 300ms linear;
    }
  }

  &.collapsed {
    max-height: 0 !important;
    opacity: 0.5;
  }

  &.animating {
    overflow-y: hidden;
  }

  hr {
    height: 0;
    background: transparent;
    border: 0;
    border-top: 1px solid lighten($ui-base-color, 12%);
    margin: 10px 0;
  }

  // notif cleaning drawer
  &.ncd {
    transition: none;

    &.collapsed {
      max-height: 0;
      opacity: 0.7;
    }
  }
}

.column-header__collapsible-inner {
  background: var(--ui-body-color);
  padding: 15px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

.column-header__setting-btn {
  &:hover,
  &:focus {
    color: var(--ui-font-dimmest-color);
    text-decoration: underline;
  }
}

.column-header__collapsible__extra + .column-header__setting-btn {
  padding-top: 5px;
}

.column-header__permission-btn {
  display: inline;
  font-weight: inherit;
  text-decoration: underline;
}

.column-header__setting-arrows {
  float: right;

  .column-header__setting-btn {
    padding: 5px;

    &:first-child {
      padding-inline-end: 7px;
    }

    &:last-child {
      padding-inline-start: 7px;
      margin-inline-start: 5px;
    }
  }
}

.column-header__title {
  display: inline-block;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  flex: 1;
}

.column-header__issue-btn {
  color: $warning-red;

  &:hover {
    color: $error-red;
    text-decoration: underline;
  }
}

.column-header__icon {
  display: inline-block;
  margin-inline-end: 5px;
}

.column-settings__pillbar {
  display: flex;
  overflow: hidden;
  background-color: transparent;
  border: 0;
  margin-bottom: 10px;
  align-items: stretch;
  gap: 2px;
}

.pillbar-button {
  color: var(--ui-font-color);
  padding: 10px;
  margin: 0;
  font-size: inherit;
  flex: auto;
  background: transparent;
  transition: all 0.2s ease;
  transition-property: color, background-color, box-shadow, border-color;
  border: 3px solid var(--ui-highlight-color);

  &:first-child {
    border-radius: 20px 0 0 20px;
  }

  &:last-child {
    border-radius: 0 20px 20px 0;
  }

  &[disabled] {
    cursor: not-allowed;
    opacity: 0.5;
  }

  &:not([disabled]) {
    &:hover,
    &:focus {
      background: var(--ui-font-color);
      border-color: var(--ui-font-color);
      color: var(--ui-base-color);
    }

    &.active {
      background: var(--ui-highlight-color);
      color: var(--ui-highlight-button-text-color);

      &:hover,
      &:focus {
        background: var(--ui-highlight-color);
        color: var(--ui-highlight-button-text-color);
      }
    }
  }
}

.limited-account-hint {
  p {
    color: var(--ui-font-secondary-color);
    font-size: 0.95rem;
    font-weight: 500;
    margin-bottom: 20px;
  }
}

.empty-column-indicator,
.follow_requests-unlocked_explanation {
  color: var(--ui-font-dimmer-color);
  background: var(--ui-base-color);
  text-align: center;
  padding: 20px;
  font-size: 0.95rem;
  font-weight: 400;
  cursor: default;
  display: flex;
  flex: 1 1 auto;
  justify-content: center;

  & > span {
    max-width: 500px;
  }

  a {
    color: var(--ui-highlight-color);
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}

.follow_requests-unlocked_explanation {
  background: var(--ui-base-color);
  contain: initial;
  flex-grow: 0;
}

.error-column {
  padding: 20px;
  background: var(--ui-base-color);
  border-radius: 4px;
  display: flex;
  flex: 1 1 auto;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  cursor: default;

  &__image {
    width: 70%;
    max-width: 350px;
    margin-top: -50px;
  }

  &__message {
    text-align: center;
    color: var(--ui-font-dimmest-color);
    font-size: 0.95rem;

    h1 {
      font-size: 1.8rem;
      line-height: 1.1;
      font-weight: 700;
      margin-bottom: 0.95rem;
      color: var(--ui-font-color);
    }

    p {
      max-width: 48ch;
    }

    &__actions {
      margin-top: 30px;
      display: flex;
      gap: 10px;
      align-items: center;
      justify-content: center;
    }
  }
}

.column-inline-form {
  padding: 7px 15px;
  padding-inline-end: 5px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background: var(--ui-base-color);

  label {
    flex: 1 1 auto;

    input {
      width: 100%;
      margin-bottom: 6px;

      &:focus {
        outline: 0;
      }
    }
  }

  .icon-button {
    flex: 0 0 auto;
    margin: 0 5px;
  }
}

.column-settings__outer {
  background: var(--ui-base-lighter-color);
  padding: 15px;
}

.column-settings__section {
  color: var(--ui-font-dimmest-color);
  cursor: default;
  display: block;
  font-weight: 500;
  margin-bottom: 10px;
}

.column-settings__row--with-margin {
  margin-bottom: 15px;
}

.column-settings__hashtags {
  .column-settings__row {
    margin-bottom: 15px;
  }

  .column-select {
    &__control {
      @include search-input;

      &::placeholder {
        color: var(--ui-font-dimmest-color);
      }

      &::-moz-focus-inner {
        border: 0;
      }

      &::-moz-focus-inner,
      &:focus,
      &:active {
        outline: 0 !important;
      }

      &:focus {
        background: var(--ui-base-color);
      }

      @media screen and (width <= 600px) {
        font-size: 1rem;
      }
    }

    &__placeholder {
      color: var(--ui-font-dimmer-color);
      padding-inline-start: 2px;
      font-size: 0.85rem;
    }

    &__value-container {
      padding-inline-start: 6px;
    }

    &__multi-value {
      background: var(--ui-base-color);

      &__remove {
        cursor: pointer;

        &:hover,
        &:active,
        &:focus {
          background: var(--ui-base-lighter-color);
          color: var(--ui-font-dimmest-color);
        }
      }
    }

    &__multi-value__label,
    &__input,
    &__input-container {
      color: var(--ui-font-dimmest-color);
    }

    &__clear-indicator,
    &__dropdown-indicator {
      cursor: pointer;
      transition: none;
      color: var(--ui-font-dimmer-color);

      &:hover,
      &:active,
      &:focus {
        color: var(--ui-font-dimmer-color);
      }
    }

    &__indicator-separator {
      background-color: var(--ui-base-lighter-color);
    }

    &__menu {
      @include search-popout;

      padding: 0;
      background: $ui-secondary-color;
    }

    &__menu-list {
      padding: 6px;
    }

    &__option {
      color: var(--ui-inverted-font-color);
      border-radius: 4px;
      font-size: 0.9rem;

      &--is-focused,
      &--is-selected {
        background: darken($ui-secondary-color, 10%);
      }
    }
  }
}

.column-settings__row {
  .text-btn:not(.column-header__permission-btn) {
    margin-bottom: 15px;
  }
}

.notifications-permission-banner {
  padding: 30px;
  border-bottom: 1px solid var(--ui-base-lighter-color);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;

  &__close {
    position: absolute;
    top: 10px;
    inset-inline-end: 10px;
  }

  h2 {
    font-size: 1rem;
    font-weight: 500;
    margin-bottom: 15px;
    text-align: center;
  }

  p {
    color: var(--ui-font-dimmest-color);
    margin-bottom: 15px;
    text-align: center;
  }
}

.column-title {
  text-align: center;
  padding: 40px;

  .logo {
    width: 50px;
    margin: 0 auto;
    margin-bottom: 40px;
  }

  h3 {
    font-size: 1.8rem;
    line-height: 1.5;
    font-weight: 700;
    margin-bottom: 10px;
  }

  p {
    font-size: 1rem;
    font-weight: 400;
    color: var(--ui-font-dimmest-color);
  }
}

.follow-recommendations-container {
  display: flex;
  flex-direction: column;
}

.column-actions {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding: 40px;
  padding-top: 40px;
  padding-bottom: 200px;
  flex-grow: 1;
  position: relative;

  &__background {
    position: absolute;
    inset-inline-start: 0;
    bottom: 0;
    height: 220px;
    width: auto;
  }
}

.column-list {
  margin: 0 20px;
  border: 1px solid var(--ui-base-lighter-color);
  background: var(--ui-base-color);
  border-radius: 4px;

  &__empty-message {
    padding: 40px;
    text-align: center;
    font-size: 1rem;
    line-height: 1.4em;
    font-weight: 400;
    color: var(--ui-font-dimmest-color);
  }
}

.dismissable-banner {
  position: relative;
  margin-bottom: 5px;
  border-radius: 8px;
  border: 1px solid var(--ui-highlight-color);
  background: var(--ui-body-color);
  padding-inline-end: 45px;
  overflow: hidden;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;

  &__background-image {
    width: 125%;
    position: absolute;
    bottom: -25%;
    inset-inline-end: -25%;
    z-index: -1;
    opacity: 0.15;
    mix-blend-mode: luminosity;
  }

  &__message {
    flex: 1 1 auto;
    padding: 15px;
    font-size: 0.9rem;
    line-height: 1.6rem;
    font-weight: 500;
    color: var(--ui-font-color);

    p {
      margin-bottom: 15px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    h1 {
      color: var(--ui-highlight-color);
      font-size: 1.5rem;
      line-height: 3em;
      font-weight: 700;
      margin-bottom: 15px;
    }

    &__actions {
      display: flex;
      flex-wrap: wrap;
      gap: 4px;

      &__wrapper {
        display: flex;
        margin-top: 30px;
      }

      .button {
        display: block;
        flex-grow: 1;
      }
    }

    .button-tertiary {
      background: transparent;
      backdrop-filter: blur(8px);
    }
  }

  &__action {
    position: absolute;
    inset-inline-end: 0;
    top: 0;
    padding: 10px;

    .icon-button {
      color: var(--ui-highlight-color);
    }
  }
}

.right_column {
  .account-card:nth-child(n + 4) {
    display: none;
  }

  .trends__item:nth-child(n + 6) {
    display: none;
  }

  .account-card {
    margin-top: 15px;
  }

  .account-card:first-child {
    margin-top: 0;
  }
}

.ui__header__left {
  display: inline-flex;
  align-items: center;
  padding-left: 10px;
}

.ui__header__links .icon-button {
  padding: 8px;
  box-sizing: content-box;
}

.ui__header__links {
  .button {
    i {
      font-size: 1.4rem;
      margin-left: 6px;
      vertical-align: middle;
    }
  }
}

.ui__header__left .column-link {
  font-size: 1rem;
  line-height: 1;
  font-weight: bold;
}

.wordmark {
  img {
    display: none;
    height: 46px !important;
    width: auto !important;
  }
}

.wordmark_dark {
  img {
    display: inherit;
    height: 46px !important;
    width: auto !important;
  }
}

.logo-container-light {
  display: none;
}

.logo-container-dark {
  display: inherit;
}

.ui__header__left {
  .column-link {
    display: block;
    font-size: 1rem;
    padding: 15px;
    text-decoration: none;
    overflow: hidden;
    white-space: nowrap;
    color: var(--ui-font-color);
    opacity: 0.8;
    transition: opacity 0.2s;

    &:hover {
      opacity: 1;
    }

    &.active {
      color: var(--ui-highlight-color);
      opacity: 1;
    }
  }
}

.ui__header__links {
  .icon-button {
    color: var(--ui-highlight-color);

    &:hover {
      color: var(--ui-font-color);
      background: transparent;
    }
  }

  .column-link {
    font-size: 1.6rem !important;
    line-height: 1 !important;
    padding: 0 !important;
    padding-right: 15px !important;
    overflow: visible;
    color: transparent;

    @media screen and (prefers-reduced-motion: no-preference) {
      & {
        transition:
          color 0.2s,
          background 0.2s,
          -webkit-text-stroke 0.2s;
      }
    }

    .fa {
      -webkit-text-stroke: 1.8px var(--ui-highlight-color);

      &:hover {
        -webkit-text-stroke: 1.8px var(--ui-font-color);
      }
    }

    &.active {
      color: var(--ui-highlight-color);
      -webkit-text-stroke: 0 transparent;

      i {
        -webkit-text-stroke: 0 transparent;
      }
    }

    span {
      display: none;
    }
  }

  .icon-with-badge__badge {
    margin-top: 5px;
    -webkit-text-stroke: 0;
    color: var(--ui-highlight-button-text-color);
  }

  @media screen and (min-width: $no-gap-breakpoint) {
    .button {
      margin-right: 15px !important;
    }
  }
}

.right_column {
  .explore__tags-header {
    margin-top: 0;
  }

  .account-card {
    padding-bottom: 5px;
  }

  .account-card__actions {
    display: none;
  }

  .account-card__header {
    height: 10px;

    img {
      display: none;
    }
  }
}

@media screen and (max-width: $widescreen-breakpoint) {
  .right_column .explore__suggested-header {
    display: none;
  }

  .right_column__home {
    display: none;
  }
}

h1#Misc {
  margin-bottom: 0;
  margin-left: 25px;
}

.getting-started__wrapper {
  .column-link {
    background: var(--ui-base-color);
    color: var(--ui-font-color);
    display: block;
    font-size: 1rem;
    padding: 15px;
    text-decoration: none;
    overflow: hidden;
    white-space: nowrap;

    &:hover,
    &:focus,
    &:active {
      background: var(--ui-base-lighter-color);
    }

    &:focus {
      outline: 0;
    }

    &--transparent {
      background: transparent;
      color: $ui-secondary-color;

      &:hover,
      &:focus,
      &:active {
        background: transparent;
        color: var(--ui-font-color);
      }

      &.active {
        color: var(--ui-font-color);
      }
    }

    &--logo {
      background: transparent;
      padding: 10px;

      &:hover,
      &:focus,
      &:active {
        background: transparent;
      }
    }
  }
}

.list-panel .column-link {
  @media screen and (prefers-reduced-motion: no-preference) {
    & {
      transition:
        color 0.2s,
        background 0.2s,
        -webkit-text-stroke 0.2s;
    }
  }
}
