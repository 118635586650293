.search {
  margin-bottom: 10px;
  position: relative;

  &__popout {
    box-sizing: border-box;
    display: none;
    position: absolute;
    inset-inline-start: 0;
    margin-top: -2px;
    width: 100%;
    background: var(--ui-base-color);
    border-radius: 0 0 4px 4px;
    box-shadow: 4px 4px 6px rgba($base-shadow-color, 0.4);
    z-index: 99;
    font-size: 0.8rem;
    padding: 15px 5px;

    h4 {
      text-transform: uppercase;
      color: var(--ui-font-dimmer-color);
      font-weight: 500;
      padding: 0 10px;
      margin-bottom: 10px;
    }

    &__menu {
      margin-bottom: 20px;

      &:last-child {
        margin-bottom: 0;
      }

      &__message {
        color: var(--ui-font-dimmer-color);
        padding: 0 10px;
      }

      &__item {
        display: block;
        box-sizing: border-box;
        width: 100%;
        border: 0;
        font: inherit;
        background: transparent;
        color: var(--ui-font-dimmest-color);
        padding: 10px;
        cursor: pointer;
        border-radius: 4px;
        text-align: start;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;

        &--flex {
          display: flex;
          justify-content: space-between;
        }

        .icon-button {
          transition: none;
        }

        &:hover,
        &:focus,
        &:active,
        &.selected {
          background: var(--ui-highlight-color);
          color: var(--ui-font-color);

          .icon-button {
            color: var(--ui-font-color);
          }
        }

        mark {
          background: transparent;
          font-weight: 700;
          color: var(--ui-font-color);
        }

        span {
          overflow: inherit;
          text-overflow: inherit;
        }
      }
    }
  }

  &.active {
    .search__popout {
      display: block;
    }
  }
}

.search__input {
  @include search-input;

  display: block;
  padding: 15px;
  padding-inline-end: 30px;
  line-height: 1.1;
  font-size: 1rem;
  border-radius: 0;
  border-bottom: 2px solid;
  background: transparent;

  &::placeholder {
    color: var(--ui-font-dimmest-color);
  }

  &::-moz-focus-inner {
    border: 0;
  }

  &::-moz-focus-inner,
  &:focus,
  &:active {
    outline: 0 !important;
  }
}

.search__icon {
  &::-moz-focus-inner {
    border: 0;
  }

  &::-moz-focus-inner,
  &:focus {
    outline: 0 !important;
  }

  .fa {
    position: absolute;
    top: 16px;
    inset-inline-end: 10px;
    z-index: 2;
    display: inline-block;
    opacity: 0;
    transition: all 100ms linear;
    transition-property: color, transform, opacity;
    font-size: 1.1rem;
    width: 18px;
    height: 18px;
    color: var(--ui-font-secondary-color);
    cursor: default;
    pointer-events: none;

    &.active {
      pointer-events: auto;
      opacity: 0.3;
    }
  }

  .fa-search {
    transform: rotate(0deg);

    &.active {
      pointer-events: auto;
      opacity: 0.3;
    }
  }

  .fa-times-circle {
    top: 17px;
    transform: rotate(0deg);
    cursor: pointer;

    &.active {
      transform: rotate(90deg);
    }

    &:hover {
      color: var(--ui-font-color);
    }
  }
}

.search-results__header {
  color: var(--ui-font-dimmer-color);
  background: lighten($ui-base-color, 2%);
  padding: 15px;
  font-weight: 500;
  font-size: 1rem;
  cursor: default;

  .fa {
    display: inline-block;
    margin-inline-end: 5px;
  }
}

.search-results__info {
  padding: 20px;
  color: var(--ui-font-dimmest-color);
  text-align: center;
}

.trends {
  &__header {
    color: var(--ui-font-dimmer-color);
    background: lighten($ui-base-color, 2%);
    font-weight: 500;
    padding: 15px;
    font-size: 1rem;
    cursor: default;

    .fa {
      display: inline-block;
      margin-inline-end: 5px;
    }
  }

  &__item {
    &:last-child {
      border-bottom: 0;
    }

    a {
      width: 100%;
      padding: 15px;
      border-radius: var(--ui-border-radius);
      transition:
        background 0.2s,
        color 0.2s;
      color: var(--ui-highlight-color);
      text-decoration: none;
      font-size: 0.9rem;
      font-weight: 500;
      display: flex;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      box-sizing: border-box;

      &:hover,
      &:focus,
      &:active {
        background: var(--ui-highlight-color);
        color: var(--ui-highlight-button-text-color);

        .hashname,
        span {
          color: var(--ui-highlight-button-text-color) !important;
        }

        .trends__item__sparkline path:first-child {
          fill: var(--ui-highlight-button-text-color) !important;
        }

        .trends__item__sparkline path:last-child {
          stroke: var(--ui-highlight-button-text-color) !important;
        }
      }

      .trends__item__name {
        white-space: nowrap;
        font-weight: bold;
        color: var(--ui-highlight-color);
        flex: 1 1;
        max-width: 360px;
        text-overflow: ellipsis;
        overflow: hidden;

        > span {
          color: var(--ui-font-secondary-color);
        }

        strong {
          font-weight: 500;
          text-overflow: ellipsis;
          overflow: hidden;
        }

        .hashname {
          font-weight: bold;

          span {
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }

      .trends__item__current {
        flex: 0 0 auto;
        font-size: 1.4rem;
        font-weight: 500;
        text-align: end;
        color: var(--ui-font-secondary-color);
        text-decoration: none;
      }

      .trends__item__sparkline {
        width: 80px;

        path:first-child {
          fill: var(--ui-highlight-color) !important;
          fill-opacity: 0.25 !important;
        }

        path:last-child {
          stroke: var(--ui-highlight-color) !important;
          fill: none !important;
        }
      }
    }

    &--requires-review {
      .trends__item__name {
        color: var(--ui-highlight-color);

        a {
          color: var(--ui-highlight-color);
        }
      }

      .trends__item__current {
        color: var(--ui-highlight-color);
      }

      .trends__item__sparkline {
        path:first-child {
          fill: var(--ui-highlight-color) !important;
        }

        path:last-child {
          stroke: var(--ui-highlight-color) !important;
        }
      }
    }

    &--disabled {
      .trends__item__name {
        color: lighten($ui-base-color, 12%);

        a {
          color: lighten($ui-base-color, 12%);
        }
      }

      .trends__item__current {
        color: lighten($ui-base-color, 12%);
      }

      .trends__item__sparkline {
        path:first-child {
          fill: var(--ui-highlight-color) !important;
        }

        path:last-child {
          stroke: var(--ui-highlight-color) !important;
        }
      }
    }
  }

  &--compact &__item {
    padding: 10px;
  }
}
