.compose-panel {
  width: 285px;
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  height: calc(100% - 10px);
  overflow-y: hidden;

  .hero-widget {
    box-shadow: none;

    &__text,
    &__img,
    &__img img {
      border-radius: 0;
    }

    &__text {
      padding: 15px;
      color: var(--ui-font-secondary-color);

      strong {
        font-weight: 700;
        color: var(--ui-font-color);
      }
    }
  }

  .search__input {
    line-height: 1.2;
    font-size: 1rem;
    padding: 15px;
    padding-inline-end: 30px;
  }

  .search__icon .fa {
    top: 15px;
  }

  .navigation-bar {
    flex: 0 1 48px;
  }

  .compose-form {
    flex: 1;
    overflow-y: hidden;
    display: flex;
    flex-direction: column;
    min-height: 310px;
  }

  .compose-form__autosuggest-wrapper {
    overflow-y: auto;
    background-color: $white;
    border-radius: 4px 4px 0 0;
    flex: 0 1 auto;
  }

  .autosuggest-textarea__textarea {
    overflow-y: hidden;
  }
}

.navigation-panel {
  margin-top: 10px;
  margin-bottom: 10px;
  height: calc(100% - 20px);
  overflow-y: auto;
  display: flex;
  flex-direction: column;

  & > a {
    flex: 0 0 auto;
  }

  .logo {
    height: 30px;
    width: auto;
  }
}

.navigation-panel,
.compose-panel {
  hr {
    flex: 0 0 auto;
    border: 0;
    background: transparent;
    border-top: 1px solid lighten($ui-base-color, 4%);
    margin: 10px 0;
  }

  .flex-spacer {
    background: transparent;
  }
}

@media screen and (width >= 600px) {
  .tabs-bar__link {
    span {
      display: inline;
    }
  }
}

.columns-area--mobile {
  flex-direction: column;
  width: 100%;
  margin: 0 auto;

  .column,
  .drawer {
    width: 100%;
    height: 100%;
    padding: 0;
  }

  .account-card {
    margin-bottom: 0;
  }

  .filter-form {
    display: flex;
  }

  .autosuggest-textarea__textarea {
    font-size: 1rem;
  }

  .search__input {
    line-height: 1.2;
    font-size: 1rem;
    padding: 15px;
    padding-inline-end: 30px;
  }

  .search__icon .fa {
    top: 15px;
  }

  .scrollable {
    overflow: visible;

    @supports (display: grid) {
      contain: content;
    }
  }

  @media screen and (min-width: $no-gap-breakpoint) {
    padding: 10px 0;
    padding-top: 0;
  }

  .detailed-status {
    padding: 15px;

    .media-gallery,
    .video-player,
    .audio-player {
      margin-top: 15px;
    }
  }

  .account__header__bar {
    padding: 5px 10px;
  }

  .navigation-bar {
    padding: 15px;
  }

  .notification__report {
    padding: 15px;
    padding-inline-start: (48px + 15px * 2);
    min-height: 48px + 2px;

    &__avatar {
      inset-inline-start: 15px;
      top: 17px;
    }
  }

  .status {
    padding: 15px;
    min-height: 48px + 2px;

    .media-gallery,
    &__action-bar,
    .video-player,
    .audio-player {
      margin-top: 10px;
    }
  }

  .account {
    padding: 15px 10px;

    &__header__bio {
      margin: 0 -10px;
    }
  }

  .notification {
    &__message {
      padding-top: 15px;
    }

    .status {
      padding-top: 8px;
    }

    .account {
      padding-top: 8px;
    }
  }
}

@media screen and (min-width: $no-gap-breakpoint) {
  .tabs-bar {
    width: 100%;
  }

  .react-swipeable-view-container .columns-area--mobile {
    height: calc(100% - 10px) !important;
  }

  .getting-started__wrapper {
    margin-bottom: 10px;
  }

  .tabs-bar__link.optional {
    display: none;
  }

  /* .search-page .search {
    display: none;
  } */

  /* .navigation-panel__legal {
    display: none;
  } */
}

@media screen and (max-width: $no-gap-breakpoint - 1px) {
  #compose_button {
    position: fixed;
    bottom: 75px;
    right: 5px;
    box-shadow: 1px 1px 4px var(--ui-shadow-color);
    height: 60px;
    border-radius: 100%;
    width: 60px;
    box-sizing: border-box;
    z-index: 1000;

    i {
      margin-left: 0 !important;
      display: block;
    }

    span {
      display: none;
    }
  }

  .columns-area__panels__main {
    width: 100%;
  }

  .columns-area__panels {
    min-height: calc(100vh - $ui-header-height);
  }

  .layout-single-column .ui__header {
    display: flex;
    border-bottom: 1px solid var(--ui-page-border-color);
  }

  .column-header,
  .column-back-button,
  .scrollable,
  .error-column {
    border-radius: 0 !important;
  }

  .columns-area__panels__main {
    width: 100%;
  }

  .ui__mobile__footer__wrapper {
    position: fixed;
    width: 100vw;
    background-color: var(--ui-page-background);
    bottom: 0;
    left: 0;
    border-top: 1px var(--ui-page-border-color) solid;
    text-align: center;

    .icon-with-badge__badge {
      -webkit-text-stroke: 0 transparent;
    }

    span {
      display: flex;
      flex-grow: 100;
    }

    button {
      height: auto !important;
      width: auto !important;
      text-align: center;
    }

    .column-link,
    .icon-button {
      font-size: 1.8rem !important;
      color: transparent;
      transition:
        color 0.2s,
        -webkit-text-stroke 0.2s;
      display: unset;
      flex-grow: 100;
      text-decoration: none;
      -webkit-text-stroke: 1.8px var(--ui-highlight-color);

      &:hover {
        -webkit-text-stroke-color: transparent;
        color: var(--ui-font-color);
      }

      &.active {
        color: var(--ui-highlight-color);
        -webkit-text-stroke: 0 transparent;
      }
    }
  }

  .ui__mobile__footer {
    display: flex;
    flex-direction: row;
    padding: 1rem;
  }

  .columns-area__panels {
    padding-bottom: 10rem;
  }

  .ui__header .column-link {
    font-size: 1.6rem;

    span {
      display: none;
    }

    i {
      display: inline !important;
    }
  }

  .compose-form__bottom-buttons {
    &.spoiler-button--clicked {
      flex-flow: row wrap;
    }

    .spoiler_wrapper {
      .spoiler-input {
        padding-bottom: 0;

        &.spoiler-input--visible {
          flex: 1 100%;
          padding-bottom: 12px;
        }
      }
    }
  }
}

.explore__search-header {
  display: flex;
}

@media screen and (max-width: $no-gap-breakpoint - 1px) {
  .explore__search-header {
    display: flex;
  }
}
