.drawer {
  width: 300px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
  padding: 10px 5px;
  flex: none;

  &:first-child {
    padding-inline-start: 10px;
  }

  &:last-child {
    padding-inline-end: 10px;
  }

  @include single-column('screen and (max-width: 630px)') {
    flex: auto;
  }

  @include limited-single-column('screen and (max-width: 630px)') {
    &,
    &:first-child,
    &:last-child {
      padding: 0;
    }
  }

  .wide & {
    min-width: 300px;
    max-width: 400px;
    flex: 1 1 200px;
  }

  @include single-column('screen and (max-width: 630px)') {
    :root & {
      //  Overrides `.wide` for single-column view
      flex: auto;
      width: 100%;
      min-width: 0;
      max-width: none;
      padding: 0;
    }
  }

  .react-swipeable-view-container & {
    height: 100%;
  }
}

.drawer__header {
  flex: none;
  font-size: 1rem;
  background: var(--ui-highlight-color);
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
  border-radius: 4px;
  overflow: hidden;

  & > * {
    display: block;
    box-sizing: border-box;
    padding: 15px 5px 13px;
    height: 48px;
    flex: 1 1 auto;
    color: var(--ui-highlight-button-text-color);
    text-align: center;
    text-decoration: none;
    cursor: pointer;
  }

  a {
    transition:
      background 100ms ease-in,
      color 100ms ease-in;

    &:focus,
    &:hover {
      outline: none;
      background: var(--ui-body-color);
      color: var(--ui-font-color);
      transition: background 200ms ease-out;
    }
  }
}

.search {
  position: relative;
  margin-bottom: 10px;
  flex: none;

  @include limited-single-column(
    'screen and (max-width: #{$no-gap-breakpoint})'
  ) {
    margin-bottom: 0;
  }
  @include single-column('screen and (max-width: 630px)') {
    font-size: 1rem;
  }
}

.search-popout {
  @include search-popout;
}

.navigation-bar {
  padding: 10px;
  color: var(--ui-font-dimmest-color);
  display: flex;
  align-items: center;

  a {
    color: inherit;
    text-decoration: none;
  }

  .acct {
    display: block;
    color: var(--ui-font-secondary-color);
    font-weight: 500;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.navigation-bar__profile {
  flex: 1 1 auto;
  margin-inline-start: 8px;
  overflow: hidden;
}

.drawer--results {
  overflow-x: hidden;
  overflow-y: scroll;
}

.search-results__section {
  margin-bottom: 5px;

  &:last-child {
    border-bottom: 0;
  }

  &__header {
    cursor: default;
    padding: 15px 0;
    font-size: 1.4rem;
    color: var(--ui-highlight-color);
    display: flex;
    justify-content: space-between;
    font-weight: bold;
    margin-top: 14px;

    h3 .fa {
      margin-inline-end: 5px;
    }

    button {
      color: var(--ui-highlight-color);
      padding: 0;
      border: 0;
      background: 0;
      font: inherit;

      &:hover,
      &:active,
      &:focus {
        text-decoration: underline;
      }
    }
  }

  .account:last-child,
  & > div:last-child .status {
    border-bottom: 0;
  }

  & > .hashtag {
    display: block;
    padding: 10px;
    color: var(--ui-font-secondary-color);
    text-decoration: none;

    &:hover,
    &:active,
    &:focus {
      color: var(--ui-font-secondary-color);
      text-decoration: underline;
    }
  }
}

.drawer__pager {
  box-sizing: border-box;
  padding: 0;
  flex-grow: 1;
  position: relative;
  overflow: hidden;
  display: flex;
  border-radius: 4px;
  z-index: 1;
}

.drawer__inner {
  position: absolute;
  top: 0;
  inset-inline-start: 0;
  box-sizing: border-box;
  padding: 0;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  overflow-y: auto;
  width: 100%;
  height: 100%;
  z-index: 1;

  &.darker {
    background: var(--ui-base-color);
  }
}

.drawer__inner__mascot {
  flex: 1;
  min-height: 47px;
  display: none;

  > img {
    display: block;
    object-fit: contain;
    object-position: bottom left;
    width: 85%;
    height: 100%;
    pointer-events: none;
    user-select: none;
  }

  > .mascot {
    display: block;
    width: 100%;
    height: 100%;
    border: 0;
    cursor: inherit;
  }

  @media screen and (height >= 640px) {
    display: block;
  }
}

.pseudo-drawer {
  background: var(--ui-body-color);
  font-size: 0.85rem;
  text-align: start;
}

.drawer__backdrop {
  cursor: pointer;
  position: absolute;
  top: 0;
  inset-inline-start: 0;
  width: 100%;
  height: 100%;
  background: rgba($base-overlay-background, 0.5);
}
