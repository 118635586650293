.list-editor {
  background: var(--ui-base-color);
  flex-direction: column;
  border-radius: 8px;
  box-shadow: 2px 4px 15px var(--ui-dropdown-shadow);
  width: 380px;
  overflow: hidden;

  @media screen and (width <= 420px) {
    width: 90%;
  }

  h4 {
    padding: 15px 0;
    background: var(--ui-base-lighter-color);
    font-weight: 500;
    font-size: 1rem;
    text-align: center;
    border-radius: 8px 8px 0 0;
  }

  .drawer__pager {
    height: 50vh;
  }

  .drawer__inner {
    border-radius: 0 0 8px 8px;

    &.backdrop {
      width: calc(100% - 60px);
      box-shadow: 2px 4px 15px var(--ui-dropdown-shadow);
      border-radius: 0 0 0 8px;
      background: var(--ui-body-secondary-color);
    }
  }

  &__accounts {
    overflow-y: auto;
  }

  .account__display-name {
    &:hover strong {
      text-decoration: none;
    }
  }

  .account__avatar {
    cursor: default;
  }

  .search {
    margin-bottom: 0;
  }
}

.list-adder {
  background: var(--ui-base-color);
  flex-direction: column;
  border-radius: 8px;
  box-shadow: 2px 4px 15px var(--ui-dropdown-shadow);
  width: 380px;
  overflow: hidden;

  @media screen and (width <= 420px) {
    width: 90%;
  }

  &__account {
    background: var(--ui-base-lighter-color);
  }

  &__lists {
    background: var(--ui-base-lighter-color);
    height: 50vh;
    border-radius: 0 0 8px 8px;
    overflow-y: auto;
  }

  .list {
    padding: 10px;
    border-bottom: 1px solid var(--ui-base-lighter-color);
  }

  .list__wrapper {
    display: flex;
  }

  .list__display-name {
    flex: 1 1 auto;
    overflow: hidden;
    text-decoration: none;
    font-size: 1rem;
    padding: 10px;
  }
}

.modal-root__modal.list-editor {
  .drawer__inner.backdrop {
    width: 100%;
    box-shadow: none;
    transform: none !important;
  }
}
