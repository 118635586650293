.error-boundary {
  color: var(--ui-font-color);
  font-size: 0.95rem;
  line-height: 1.25em;

  h1 {
    font-size: 1.5rem;
    line-height: 1.5em;
    font-weight: 400;
    margin-bottom: 8px;
  }

  a {
    color: var(--ui-font-color);
    text-decoration: underline;
  }

  ul {
    list-style: disc;
    margin-inline-start: 0;
    padding-inline-start: 1em;
  }

  textarea.web_app_crash-stacktrace {
    width: 100%;
    resize: none;
    white-space: pre;
    font-family: $font-monospace, monospace;
  }
}
