.account-card__header {
  position: relative;
}

.explore__search-header {
  justify-content: center;
  align-items: center;
  padding: 15px;

  .search {
    width: 100%;
    margin-bottom: 0;
  }

  .search__input {
    padding: 10px;
  }

  .search .fa {
    top: 10px;
    inset-inline-end: 10px;
  }

  .search .fa-times-circle {
    top: 12px;
  }
}

.story {
  display: flex;
  align-items: center;
  color: var(--ui-font-color);
  text-decoration: none;
  padding: 15px 0;
  border-bottom: 1px solid lighten($ui-base-color, 8%);

  &:last-child {
    border-bottom: 0;
  }

  &:hover,
  &:active,
  &:focus {
    background-color: lighten($ui-base-color, 4%);
  }

  &__details {
    padding: 0 15px;
    flex: 1 1 auto;

    &__publisher {
      color: var(--ui-font-dimmest-color);
      margin-bottom: 4px;
    }

    &__title {
      font-size: 1.15rem;
      line-height: 1.15em;
      font-weight: 500;
      margin-bottom: 4px;
    }

    &__shared {
      color: var(--ui-font-dimmest-color);
    }
  }

  &__thumbnail {
    flex: 0 0 auto;
    margin: 0 15px;
    position: relative;
    width: 120px;
    height: 120px;

    .skeleton {
      width: 100%;
      height: 100%;
    }

    img {
      border-radius: 4px;
      display: block;
      margin: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    &__preview {
      border-radius: 4px;
      display: block;
      margin: 0;
      width: 100%;
      height: 100%;
      object-fit: fill;
      position: absolute;
      top: 0;
      inset-inline-start: 0;
      z-index: 0;

      &--hidden {
        display: none;
      }
    }
  }
}
