.compose-form {
  padding-bottom: 20px;
  width: 100%;

  .compose-form__autosuggest-wrapper {
    border-radius: var(--ui-border-radius) var(--ui-border-radius) 0 0;
    background: var(--ui-body-color);
  }

  .compose-form__autosuggest-wrapper-hashtags {
    border-top: 2px dashed var(--ui-body-secondary-color);
    border-radius: 0;
    border-bottom: 0;
  }

  .emoji-picker-dropdown {
    position: absolute;
    top: 0;
    inset-inline-end: 0;

    ::-webkit-scrollbar-track:hover,
    ::-webkit-scrollbar-track:active {
      background-color: rgba($base-overlay-background, 0.3);
    }
  }
}

.character-counter {
  cursor: default;
  font-family: var(--ui-interface-font), sans-serif;
  font-size: 0.9rem;
  font-weight: 600;
  color: var(--ui-font-brighter-color);

  &.character-counter--over {
    color: $warning-red;
  }
}

.spoiler_wrapper {
  display: flex;
  width: 100%;
  justify-content: flex-end;
}

@media screen and (prefers-reduced-motion: no-preference) {
  .spoiler-input {
    transition:
      height 0.4s ease,
      opacity 0.4s ease,
      width 0.4s ease,
      margin-right 0.4s ease,
      padding-bottom 0.4s ease;
  }
}

.spoiler-input {
  transform-origin: bottom;
  opacity: 0;
  width: 0%;
  pointer-events: none;

  &.spoiler-input--visible {
    opacity: 1;
    width: 100%;
    margin-right: 10px;
    pointer-events: auto;
  }

  input {
    display: block;
    margin: 0;
    padding: 0.5rem;
    width: 100%;
    outline: 0;
    color: var(--ui-font-color);
    background: var(--ui-body-color);
    font-size: 1rem;
    font-family: inherit;
    resize: vertical;
    font-weight: bold;
    box-sizing: border-box;
    border: transparent 4px solid;
    transition: border-color 0.2s;
    height: 2.5rem;

    &::placeholder {
      color: var(--ui-font-secondary-color);
      font-weight: bold;
    }

    &:focus {
      outline: 0;
    }
    @include single-column('screen and (max-width: 630px)') {
      font-size: 1rem;
    }
  }
}

.compose-form__warning {
  color: var(--ui-inverted-font-color);
  margin-bottom: 15px;
  background: $ui-primary-color;
  box-shadow: 0 2px 6px rgba($base-shadow-color, 0.3);
  padding: 8px 10px;
  border-radius: 4px;
  font-size: 0.8rem;
  font-weight: 400;

  a {
    color: var(--ui-font-brighter-color);
    font-weight: 500;
    text-decoration: underline;

    &:active,
    &:focus,
    &:hover {
      text-decoration: none;
    }
  }
}

.compose-form__sensitive-button {
  padding: 10px;
  padding-top: 0;
  font-size: 0.9rem;
  font-weight: 500;

  &.active {
    color: var(--ui-highlight-color);
  }

  input[type='checkbox'] {
    display: none;
  }

  .checkbox {
    display: inline-block;
    position: relative;
    border: 1px solid $ui-primary-color;
    box-sizing: border-box;
    width: 18px;
    height: 18px;
    flex: 0 0 auto;
    margin-inline-start: 5px;
    margin-inline-end: 10px;
    top: -1px;
    border-radius: 4px;
    vertical-align: middle;

    &.active {
      border-color: var(--ui-highlight-color);
      background: var(--ui-highlight-color)
        url("data:image/svg+xml;utf8,<svg width='18' height='18' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M4.5 8.5L8 12l6-6' stroke='white' stroke-width='1.5'/></svg>")
        center center no-repeat;
    }
  }
}

.reply-indicator {
  margin: 0 0 10px;
  border-radius: 4px;
  padding: 10px;
  background: var(--ui-body-color);
  min-height: 23px;
  max-height: 20vh;
  overflow-y: auto;
  flex: 0 2 auto;
}

.reply-indicator__header {
  margin-bottom: 5px;
  overflow: hidden;

  & > .account.small {
    color: var(--ui-font-secondary-color);
  }
}

.reply-indicator__cancel {
  float: right;
}

.reply-indicator__content {
  position: relative;
  font-size: 0.9rem;
  line-height: 1.1em;
  word-wrap: break-word;
  font-weight: 400;
  overflow: hidden;
  padding-top: 5px;
  color: var(--ui-font-secondary-color);
  white-space: pre-wrap;

  p,
  pre {
    margin-bottom: 20px;
    white-space: pre-wrap;

    &:last-child {
      margin-bottom: 0;
    }
  }

  a {
    color: var(--ui-font-color);
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }

    &.mention {
      &:hover {
        text-decoration: none;

        span {
          text-decoration: underline;
        }
      }
    }
  }

  .emojione {
    width: 22px;
    height: 22px;
    margin: -5px 0 0;
  }
}

.compose-form .compose-form__autosuggest-wrapper {
  position: relative;
}

.compose-form .autosuggest-textarea,
.compose-form .autosuggest-input {
  position: relative;
  width: 100%;

  label {
    .autosuggest-textarea__textarea {
      display: block;
      box-sizing: border-box;
      margin: 0;
      border: 0;
      border-radius: var(--ui-border-radius) var(--ui-border-radius) 0 0;
      padding: 10px 32px 10px 10px;
      width: 100%;
      min-height: 100px;
      outline: 0;
      color: var(--ui-font-color);
      background: var(--ui-body-color);
      transition: background 0.2s;
      font-size: 1rem;
      font-family: inherit;
      resize: vertical;
      scrollbar-color: initial;

      &::placeholder {
        color: var(--ui-font-dimmer-color);
      }

      &::-webkit-scrollbar {
        all: unset;
      }

      &:focus {
        outline: 0;
        background: var(--ui-body-color);
      }

      @include single-column('screen and (max-width: 630px)') {
        font-size: 1rem;
      }

      @include limited-single-column('screen and (max-width: 600px)') {
        resize: vertical;
      }
    }

    .autosuggest-textarea__hashtagarea {
      display: block;
      box-sizing: border-box;
      margin: 0;
      border: 0;
      padding: 10px;
      width: 100%;
      min-height: 1rem;
      outline: 0;
      color: var(--ui-font-color);
      background: var(--ui-body-color);
      transition: background 0.2s;
      font-size: 1rem;
      font-family: inherit;
      resize: none;
      overflow: auto;
      white-space: nowrap;
      scrollbar-color: initial;

      &::placeholder {
        color: var(--ui-font-dimmer-color);
      }

      &::-webkit-scrollbar {
        all: unset;
      }

      &:focus {
        outline: 0;
      }

      @include single-column('screen and (max-width: 630px)') {
        font-size: 1rem;
      }

      @include limited-single-column('screen and (max-width: 600px)') {
        resize: vertical;
      }
    }
  }
}

.compose-form__textarea-icons {
  display: block;
  position: absolute;
  top: 29px;
  inset-inline-end: 5px;
  bottom: 5px;
  overflow: hidden;

  & > .textarea_icon {
    display: block;
    margin-top: 2px;
    margin-inline-start: 2px;
    width: 24px;
    height: 24px;
    color: var(--ui-font-brighter-color);
    font-size: 1.1rem;
    line-height: 1.3em;
    text-align: center;
    opacity: 0.8;
  }
}

.autosuggest-textarea__suggestions-wrapper {
  position: relative;
  height: 0;
}

.autosuggest-textarea__suggestions {
  box-sizing: border-box;
  display: none;
  position: absolute;
  top: 100%;
  width: 100%;
  z-index: 99;
  box-shadow: 4px 4px 6px rgba($base-shadow-color, 0.4);
  background: $ui-secondary-color;
  border-radius: 0 0 4px 4px;
  color: var(--ui-inverted-font-color);
  font-size: 0.9rem;
  padding: 6px;
}

.autosuggest-textarea__suggestions--visible {
  display: block;
}

.autosuggest-textarea__suggestions__item {
  padding: 10px;
  cursor: pointer;
  border-radius: 4px;

  &:hover,
  &:focus,
  &:active,
  &.selected {
    background: darken($ui-secondary-color, 10%);
  }

  > .account,
  > .emoji,
  > .autosuggest-hashtag {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    line-height: 1.1em;
    font-size: 0.9rem;
  }

  .autosuggest-hashtag {
    justify-content: space-between;

    &__name {
      flex: 1 1 auto;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    strong {
      font-weight: 500;
    }

    &__uses {
      flex: 0 0 auto;
      text-align: end;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  & > .account.small {
    .display-name {
      & > span {
        color: var(--ui-font-brighter-color);
      }
    }
  }
}

.compose-form__upload-wrapper {
  overflow: hidden;
}

.compose-form__uploads-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  font-family: inherit;
  padding: 5px 0;
  overflow: hidden;
  gap: 5px;
}

.compose-form__upload {
  flex: 1 1 0;
  margin: 5px;
  min-width: 40%;

  .compose-form__upload-thumbnail {
    position: relative;
    border-radius: 4px;
    height: 140px;
    width: 100%;
    background-color: $base-shadow-color;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    overflow: hidden;

    & > .close {
      mix-blend-mode: difference;
    }
  }

  .icon-button {
    flex: 0 1 auto;
    color: var(--ui-font-secondary-color);
    font-size: 0.9rem;
    font-weight: 500;
    padding: 10px;
    font-family: inherit;

    &:hover,
    &:focus,
    &:active {
      color: var(--ui-font-color);
    }
  }

  &__warning {
    position: absolute;
    z-index: 2;
    bottom: 0;
    inset-inline-start: 0;
    inset-inline-end: 0;
    box-sizing: border-box;
    background: linear-gradient(
      0deg,
      rgba($base-shadow-color, 0.8) 0,
      rgba($base-shadow-color, 0.35) 80%,
      transparent
    );
  }
}

.compose-form__upload__actions {
  background: linear-gradient(
    180deg,
    rgba($base-shadow-color, 0.8) 0,
    rgba($base-shadow-color, 0.35) 80%,
    transparent
  );
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.upload-progress {
  display: flex;
  padding: 10px;
  color: var(--ui-font-dimmest-color);
  overflow: hidden;

  .fa {
    font-size: 1.8rem;
    margin-inline-end: 10px;
  }

  span {
    display: block;
    font-size: 0.75rem;
    font-weight: 500;
    text-transform: uppercase;
  }
}

.upload-progress__message {
  flex: 1 1 auto;
}

.upload-progress__backdrop {
  position: relative;
  margin-top: 5px;
  border-radius: 6px;
  width: 100%;
  height: 6px;
  background: darken($simple-background-color, 8%);
}

.upload-progress__tracker {
  position: absolute;
  top: 0;
  inset-inline-start: 0;
  height: 6px;
  border-radius: 6px;
  background: var(--ui-highlight-color);
}

.compose-form__modifiers {
  color: var(--ui-font-color);
  font-family: inherit;
  font-size: 0.9rem;
  background: var(--ui-body-secondary-color);
}

.compose-form__buttons-wrapper {
  border-radius: 0 0 var(--ui-border-radius) var(--ui-border-radius);
  display: flex;
  justify-content: start;
  margin-top: 6px;
  column-gap: 6px;
  overflow: hidden;
}

.compose-form__buttons {
  display: flex;
  background: var(--ui-body-secondary-color);
  padding: 8px;

  &__right {
    flex-grow: 1;
  }

  & .icon-button,
  & .text-icon-button {
    display: inline-block;
    box-sizing: content-box;
    padding: 0 3px;
    height: 27px;
    line-height: 1;
    vertical-align: bottom;
  }

  & > hr {
    display: inline-block;
    margin: 0 3px;
    border-width: 0 0 0 1px;
    border-style: none none none solid;
    border-color: transparent transparent transparent var(--ui-highlight-color);
    padding: 0;
    width: 0;
    height: 27px;
    background: transparent;
  }
}

.character-counter__wrapper {
  align-self: center;
  margin-inline-end: 4px;
  position: absolute;
  right: 8px;
}

.privacy-dropdown {
  &.active {
    background: transparent;

    .icon-button {
      border-radius: 8px 8px 0 0;
      background-color: var(--ui-body-color);
      color: var(--ui-highlight-color);

      &:hover {
        background-color: var(--ui-highlight-color);
        color: var(--ui-highlight-button-text-color);
      }
    }
  }
}

.privacy-dropdown.active {
  .privacy-dropdown__value {
    border-radius: 4px 4px 0 0;
    box-shadow: 0 -4px 4px rgba($base-shadow-color, 0.1);
  }

  &.top .privacy-dropdown__value {
    border-radius: 0 0 4px 4px;
  }

  .privacy-dropdown__dropdown {
    display: block;
    box-shadow: 2px 4px 6px rgba($base-shadow-color, 0.1);
  }
}

.privacy-dropdown__dropdown {
  border-radius: 4px;
  box-shadow: 2px 4px 15px rgba($base-shadow-color, 0.4);
  background: var(--ui-body-color);
  overflow: hidden;
  transform-origin: 50% 0;

  .fa {
    font-size: 1.5rem;
  }
}

.privacy-dropdown__option {
  display: flex;
  align-items: center;
  padding: 10px;
  color: var(--ui-font-color);
  cursor: pointer;
  font-weight: 300;
  font-size: 0.9rem;
  line-height: 1.2;

  .privacy-dropdown__option__content {
    flex: 1 1 auto;
    color: var(--ui-font-secondary-color);

    &:not(:first-child) {
      margin-inline-start: 10px;
    }

    strong {
      display: block;
      color: var(--ui-font-color);
      font-weight: 700;
      font-size: 1rem;
    }
  }

  &:hover,
  &.active {
    background: var(--ui-highlight-color);
    color: var(--ui-highlight-button-text-color);

    .privacy-dropdown__option__content {
      color: var(--ui-highlight-button-text-color);

      strong {
        color: var(--ui-highlight-button-text-color);
      }
    }
  }
}

.compose-form__publish {
  display: flex;
  justify-content: flex-end;
  min-width: 0;
  flex: 0 0 auto;
  column-gap: 5px;

  .compose-form__publish-button-wrapper {
    .primary {
      z-index: 1;
    }

    button {
      font-size: 1.2rem;
      padding: 0.5rem 1.2rem;
      text-align: center;
      height: 2.5rem;
      transition:
        color 0.2s,
        background 0.2s,
        box-shadow 0.2s;
    }

    & > .side_arm {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      padding-left: 30px;
      right: 23px;
      transition:
        color 0.2s,
        background 0.2s,
        box-shadow 0.2s;
    }
  }

  &:not(.disabled) {
    .compose-form__publish-button-wrapper {
      button {
        &:hover {
          box-shadow: 0 0 8px var(--ui-highlight-color);
        }
      }

      & > .side_arm {
        background: var(--ui-highlight-button-text-color);
        color: var(--ui-highlight-color);

        &:hover {
          box-shadow: 0 0 8px var(--ui-highlight-button-text-color);
        }
      }
    }
  }
}

.language-dropdown {
  &__dropdown {
    background: $simple-background-color;
    box-shadow: 2px 4px 15px rgba($base-shadow-color, 0.4);
    border-radius: 4px;
    overflow: hidden;
    z-index: 2;

    &.top {
      transform-origin: 50% 100%;
    }

    &.bottom {
      transform-origin: 50% 0;
    }

    .emoji-mart-search {
      padding-inline-end: 10px;
    }

    .emoji-mart-search-icon {
      inset-inline-end: 10px + 5px;
    }

    .emoji-mart-scroll {
      padding: 0 10px 10px;
    }

    &__results {
      &__item {
        cursor: pointer;
        color: var(--ui-font-color);
        font-weight: 500;
        padding: 10px;
        border-radius: 4px;
        border: 2px transparent solid;
        transition-property: border-color, background, color;
        transition-duration: 0.2s;

        &:focus,
        &:active,
        &:hover {
          border-color: var(--ui-highlight-color);
        }

        &__common-name {
          color: var(--ui-font-secondary-color);
        }

        &.active {
          background: var(--ui-highlight-color);
          outline: 0;
        }
      }
    }
  }
}

.compose-form__bottom-buttons .icon-button {
  background: var(--ui-highlight-color);
  color: var(--ui-highlight-button-text-color);
  padding: 0.5rem;
  font-size: 1.2rem !important;
  width: auto !important;
  line-height: normal !important;
  margin-right: 10px;
  height: 2.5rem;
  transition: box-shadow 0.2s;

  &:hover {
    box-shadow: 0 0 8px var(--ui-highlight-color);
  }
}

.spoiler_button .icon-button {
  &.active {
    border-radius: 8px 0 0 8px;
    margin-right: -2px !important;
    z-index: 1;
    position: relative;
  }
}

@media screen and (prefers-reduced-motion: no-preference) {
  .spoiler_button .icon-button {
    transition:
      height 0.4s ease,
      width 0.4s ease,
      margin 0.4s ease,
      border-radius 0.4s ease;
  }
}

.compose-form__bottom-buttons {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}

.autosuggest-textarea__textarea {
  max-height: calc(80vh - 170px);
}

.compose-modal .autosuggest-textarea__textarea {
  max-height: calc(50vh - 115px);
}
