@media screen and (min-width: $widescreen-breakpoint) {
  .fixed_wrapper {
    top: 60px;
    position: fixed;
    width: inherit;
    max-width: inherit;

    > div:last-child {
      padding-bottom: 20px;
    }
  }
}

.right_column {
  @media screen and (min-width: $widescreen-breakpoint) {
    width: var(--ui-right-column-width);

    .list-panel {
      margin-bottom: 15px;
    }

    .scrollable {
      width: auto;
    }
  }

  .dismissable-banner {
    display: none;
  }

  .explore__tags-header,
  .list-panel,
  .explore__suggested-header {
    background-color: var(--ui-body-secondary-color);
    margin-bottom: 0.5rem;
    border-radius: var(--ui-border-radius);
    text-decoration: none;
    overflow: hidden;
    font-size: 1rem;
    padding: 15px;

    .trends__item__sparkline {
      display: none;
    }
  }
}

.manage-lists,
.list-panel {
  margin-top: 0;
  margin-bottom: 15px;

  a {
    font-size: 1rem;
    text-decoration: none;
    padding: 15px;
    display: block;
    border-radius: var(--ui-border-radius);
    color: var(--ui-font-color);

    &:hover {
      background: var(--ui-highlight-color);
      color: var(--ui-highlight-button-text-color);
    }
  }

  .active {
    color: var(--ui-highlight-color);
    font-weight: bold;

    &:hover {
      color: var(--ui-highlight-button-text-color);
    }
  }
}
