@media screen and (min-width: $no-gap-breakpoint) {
  .home-scroll .compose-form {
    display: block;
  }

  .status,
  .notification,
  .status__wrapper--filtered {
    margin-top: 8px;
    border-radius: var(--ui-border-radius);
  }

  .layout-single-column {
    .ui__header {
      display: flex;
      max-width: var(--ui-widescreen-width);
      margin: 0 auto;
    }

    .ui__header__wrapper {
      border-bottom: 1px var(--ui-page-border-color) solid;
      position: fixed;
      width: 100%;
      z-index: 2;
      background: var(--ui-page-background);
    }

    .columns-area__panels {
      margin-top: 60px;
      height: calc(100% - 60px);
      min-height: calc(100vh - 60px);
      transition: color 0.2s;
    }

    .detailed-status__action-bar {
      border-bottom-right-radius: var(--ui-border-radius);
      border-bottom-left-radius: var(--ui-border-radius);
    }

    .column-header__buttons {
      border-top-left-radius: var(--ui-border-radius);
      overflow: hidden;
    }

    .notification__filter-bar button,
    .column-back-button,
    .explore__search-header,
    .empty-column-indicator,
    .column-header__button,
    .tabs-bar__wrapper,
    .column-header {
      background: transparent;
    }

    .dismissable-banner {
      border-radius: var(--ui-border-radius);
      margin-top: 8px;
    }

    .explore__search-header {
      padding: 0;
      padding-top: 15px;
      padding-bottom: 15px;
    }

    .account-timeline__header {
      border-top-left-radius: var(--ui-border-radius);
      border-top-right-radius: var(--ui-border-radius);
      overflow: hidden;
    }

    #Blocked-domains,
    #Blocked-users,
    #Muted-users,
    #Lists,
    #Follow-requests,
    #Pinned-posts {
      padding-left: 40px;
    }

    .about .link-footer {
      display: block;
    }

    .columns-area__panels__pane--compositional {
      display: none;
    }

    .tabs-bar__wrapper {
      position: static;
    }

    .hideondesktop {
      display: none;
    }

    //  .tabs-bar__wrapper {
    //    border-bottom-right-radius:4px;
    //    border-bottom-left-radius:4px;
    //    overflow:hidden;
    //  }

    .column-back-button > span {
      /* Hide Back Text */
      display: none;
    }

    .column-back-button__icon {
      background: transparent;
      padding: 0.4rem !important;
      color: var(--ui-highlight-color);
      border-radius: 100px;
      transition: background 0.2s;
      border: 3px solid var(--ui-highlight-color);
      vertical-align: middle;
      text-decoration: none;
      width: 1.2rem;
      height: 1.2rem;
      transition-property: color, background-color, border;

      &:hover {
        background: var(--ui-highlight-color);
        color: var(--ui-highlight-button-text-color);
        text-decoration: none;
      }
    }

    .column-header {
      font-size: 1.2rem;
    }

    .about {
      padding: 0 !important;
    }

    .scrollable,
    .home-scroll {
      width: var(--ui-main-column-width);
    }

    .home-scroll,
    .account-scroll .scrollable-list,
    .account-scroll .scrollable_list {
      width: var(--ui-main-column-width);
    }

    .columns-area .columns-area--mobile {
      justify-content: center;
    }

    .column {
      justify-content: left;
      max-width: var(--ui-main-column-width);
    }

    .account-timeline__header {
      max-width: var(--ui-main-column-width);
      width: 100%;
    }

    .columns-area__panels__main {
      max-width: max-content;
    }

    .tabs-bar__wrapper {
      max-width: var(--ui-main-column-width) !important;
    }
  }
}

@media screen and (min-width: $widescreen-breakpoint) {
  .layout-single-column {
    .column {
      max-width: var(--ui-widescreen-width);
      box-sizing: border-box;
    }

    .columns-area__panels__main {
      padding: 0 10px;
    }

    .account-scroll {
      max-width: var(--ui-widescreen-width);

      .scrollable-list {
        margin-top: -5px;
      }
    }

    [data-column='home'],
    [data-column='local'],
    [data-column='federated'],
    [data-column='lists'],
    .column_explore {
      display: flex;
      flex-direction: row-reverse;
      row-gap: 15px;
      column-gap: 15px;
    }
  }

  .list-panel,
  .explore__suggested-header,
  .explore__tags-header {
    margin-top: 15px;
  }

  .right-column {
    display: none;
  }
}

.scrollable-list--flex {
  min-height: 150px;
  display: flex;
  align-content: center;
}

.compose_popup .compose-form {
  width: 600px;
  top: 0;
}

.compose_popup .autosuggest-textarea__textarea {
  max-height: 50vh;
}
